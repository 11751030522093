import { GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import {
	platformIconMap,
	readablePlatformsMap,
} from 'root/src/shared/constants/dropKeys'
import { GamingPlatform } from 'root/src/shared/rewards/constants'

export default {
	[GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'GAMING PLATFORMS',
		modalText:
			'Let us know what your gaming platforms you’ll stream on and we’ll deliver drops that work for you',
		modalContent: {
			buttonText: 'SUBMIT',
			platformsList: Object.keys(GamingPlatform)
				.map(platform => ({
					icon: platformIconMap[platform],
					label: readablePlatformsMap[platform],
					value: platform,
				})),
		},
	},
}
