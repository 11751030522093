export const listType = 'list'

export const payoutListType = 'payoutList'
export const cardListType = 'cardList'
export const videoCardListType = 'videoCardList'
export const projectCommentsListType = 'projectCommentsList'
export const couponListType = 'couponList'
export const sidebarDaresListType = 'sidebarDaresListType'
export const pendingBrandListType = 'pendingBrandListType'
export const sliderCardsListType = 'sliderCardsListType'
export const tableListType = 'tableListType'
export const pointsMarketplaceListType = 'pointsMarketplaceListType'
export const campaignClipsListType = 'campaignClipsListType'
export const cardItemType = 'card'
export const dropsEventListType = 'dropsEventListType'

export const dropsSliderListType = 'dropsSliderListType'
export const dropEventAccordionListType = 'dropEventAccordionListType'
export const dashboardTableListType = 'dashboardTableListType'
