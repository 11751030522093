import { ADD_POINTS_ALLOWLIST_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { responseSchema } from 'root/src/server/points/endpoints/getPointsAllowlistCreators.endpoint';
const payloadSchema = {
    type: 'object',
    properties: {
        dropId: {
            type: 'string',
        },
        creators: {
            type: 'array',
            maxItems: 1000,
            items: {
                type: 'object',
                properties: {
                    platform: { type: 'string' },
                    mnemonicId: { type: 'string' },
                },
            },
        },
    },
    required: ['dropId', 'creators'],
    additionalProperties: false,
};
export const addPointsAllowlistCreators = {
    [ADD_POINTS_ALLOWLIST_CREATORS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: AuthGroup.Admin,
        payloadSchema,
        responseSchema,
    },
};
