export default {
	type: 'object',
	properties: {
		from: { type: 'string' },
		to: { type: 'string' },
		mnemonicIds: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
	},
	additionalProperties: false,
}
