export const dropEventKey = 'dropEvent';
export const dropGameKey = 'dropGame';
export const dropDeliveredProductTypesEnum = {
    dropDeliveredKey: 'dropDeliveredKey',
    dropDeliveredCode: 'dropDeliveredCode',
    initialProduct: 'initialProduct',
};
export const brandProductTypesEnum = {
    dropKey: 'dropKey',
    dropCode: 'dropCode',
    customProduct: 'customProduct',
    placeholder: 'placeholder',
};
export const dropEventSubscriptionStatusEnum = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    APPROVED: 'APPROVED',
    EXPIRED: 'EXPIRED',
    INACTIVE: 'INACTIVE',
};
export const dropEventStatusEnum = {
    PENDING_SUBSCRIPTION: 'PENDING_SUBSCRIPTION',
    PENDING_APPROVAL: 'PENDING_APPROVAL',
    PENDING_GO_LIVE_DATE: 'PENDING_GO_LIVE_DATE',
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    EXPIRED: 'EXPIRED',
};
export const dropEventBudgetTypes = {
    MONTHLY: 'MONTHLY',
    CAMPAIGN: 'CAMPAIGN',
};
export const dropEventActivateMethodsEnum = {
    PAYPAL: 'PAYPAL',
    INVOICE: 'INVOICE',
};
export const dropDareDifficultyLevelEnum = {
    n00b: 'n00b',
    easy: 'easy',
    medium: 'medium',
    hard: 'hard',
    legendary: 'legendary',
};
export const readableDropDareDifficultyLevels = {
    [dropDareDifficultyLevelEnum.n00b]: 'N00b',
    [dropDareDifficultyLevelEnum.easy]: 'Easy',
    [dropDareDifficultyLevelEnum.medium]: 'Medium',
    [dropDareDifficultyLevelEnum.hard]: 'Hard',
    [dropDareDifficultyLevelEnum.legendary]: 'Legendary',
};
export const dropDareDifficultyLevelValue = {
    n00b: 0,
    easy: 1,
    medium: 2,
    hard: 3,
    legendary: 4,
};
export const dropDareDifficultyEstimatedTime = {
    n00b: '10 to 30 minutes',
    easy: '30 minutes to 1 hour',
    medium: '1 hour to 3 hours',
    hard: 'Over 3 hours',
    legendary: 'Who knows???',
};
export const dropDareTypeEnum = {
    creative: 'creative',
    gameplay: 'gameplay',
    review: 'review',
    speed: 'speed',
    highlights: 'highlights',
    easterEgg: 'easterEgg',
    starter: 'starter',
    guide: 'guide',
};
export const readableDropDareTypes = {
    [dropDareTypeEnum.creative]: 'Creative',
    [dropDareTypeEnum.gameplay]: 'Gameplay',
    [dropDareTypeEnum.review]: 'Review',
    [dropDareTypeEnum.speed]: 'Speed',
    [dropDareTypeEnum.highlights]: 'Highlights',
    [dropDareTypeEnum.easterEgg]: 'Easter Egg',
    [dropDareTypeEnum.starter]: 'Starter',
    [dropDareTypeEnum.guide]: 'Guide',
};
export const countdownColors = {
    seconds: '#FF007A',
    minutes: '#FFEA00',
    hours: '#5D12E4',
    days: '#00FFED',
};
export const comingSoonMaskText = 'Coming soon';
export const dropEventStatusNames = {
    [dropEventStatusEnum.PENDING_APPROVAL]: 'Pending Approval',
    [dropEventStatusEnum.ACTIVE]: 'Active',
    [dropEventStatusEnum.DEACTIVATED]: 'Deactivated',
    [dropEventStatusEnum.PENDING_GO_LIVE_DATE]: 'Pending Go-Live Date',
    [dropEventStatusEnum.PENDING_SUBSCRIPTION]: 'Pending Subscription',
    [dropEventStatusEnum.EXPIRED]: 'Expired',
};
export const pointsCalculationMethodEnum = Object.freeze({
    DEFAULT: 'default',
    CPVH: 'cpvh',
});
export const darePlatformOptions = [
    {
        value: 'any',
        label: 'Any',
    },
    {
        value: 'twitch',
        label: 'Twitch',
    },
    {
        value: 'youtube',
        label: 'YouTube',
    },
    {
        value: 'tiktok',
        label: 'TikTok',
    },
];
export const lowProductsThreshold = 11;
export const monthlyBudgetValues = [500, 1000, 2000, 5000, 10000, 25000, 50000, 75000, 100000];
export const RewardForValue = Object.freeze({
    FANS: 'fans',
    CREATOR: 'creator',
});
export const rewardForValueReadable = {
    [RewardForValue.FANS]: 'Fans',
    [RewardForValue.CREATOR]: 'Creator',
};
export const rewardForOptions = [
    { label: rewardForValueReadable[RewardForValue.FANS], value: RewardForValue.FANS },
    { label: rewardForValueReadable[RewardForValue.CREATOR], value: RewardForValue.CREATOR },
];
export const notAbleToParticipateMessage = 'Unfortunately, you\'re ineligible for this Drop. Reasons can range from content preferences, affiliations, or offline conduct. If you believe there may be an error, kindly submit a ticket for further assistance.';
export const noMaxDaresMessage = 'You cannot accept Dares at this time due to requirements not being met.';
export const maxDaresReachedMessage = 'You have accepted all Dares that match your current CCV / Follower numbers.';
export const notEnoughViewershipMessage = 'Your viewership isn’t large enough to participate in this drop. Check the drops page for drops you can join.';
