import prop from 'ramda/src/prop'
import { KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { dropKey } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { VIEW_PROJECT_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { RewardEndpoint } from 'root/src/shared/rewards/constants'
import { sectionKey } from 'root/src/shared/constants/keys'

export default {
	[KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		recordType: dropKey,
		endpointId: [RewardEndpoint.GET_REWARDS_FOR_VIEWERS],
		defaultListDataLength: 10,
		isClientPaginated: true,
		listModuleId: KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID,
		constantListStoreKey: true,
		listTitle: 'Keys & Codes for Viewers',
		headerType: 'backgroundDesign',
		tableColumns: [
			['Name', 'name'],
			['URL', 'url'],
			['Created', 'created'],
			['Restrictions', 'restrictions'],
		],
		embeddedContent: {
			moduleType: 'embeddedForm',
			fields: [
				{
					fieldId: sectionKey,
					fieldCaption: 'Status',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: [
						{
							label: 'Available',
							payload: {
								isAll: false,
							},
						},
						{
							label: 'All',
							payload: {
								isAll: true,
							},
						},
					],
					endpointId: RewardEndpoint.GET_REWARDS_FOR_VIEWERS,
				},
			],
		},
		tableItemActions: [
			{
				name: prop('projectTitle'),
				fn: item => (item?.projectUrl
					? () => pushRouteClient({
						routeId: VIEW_PROJECT_ROUTE_ID,
						routeParams: { recordId: item.projectUrl },
					})
					: () => {}),
				header: 'Source',
				order: 1,
			},
		],
	},
}
