import { DEMO_GRAPHS_DASHBOARD_MODULE_ID, GRAPHS_DASHBOARD_MODULE_ID, } from 'root/src/shared/descriptions/modules/moduleIds';
import setModuleDataFilter from 'root/src/client/logic/api/actions/setModuleDataFilter';
import { payloadFiltersMock } from 'root/src/shared/demoDashboard/demoDashboard.mocks';
import { DEMO_DASHBOARD_RECORD_ID, demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj';
import { getDashboardFilters } from 'root/src/client/web/static/GraphsDashboard/helpers';
import graphsDashboard from 'root/src/shared/descriptions/modules/graphsDashboard';
import changeRoute from 'root/src/client/logic/route/actions/changeRoute';
import { DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
const defaultPayloadFilters = {
    from: payloadFiltersMock.from,
    to: payloadFiltersMock.to,
    ambassadorsOnly: true,
};
export const demoGraphDescriptions = {
    [DEMO_GRAPHS_DASHBOARD_MODULE_ID]: Object.assign(Object.assign({}, graphsDashboard[GRAPHS_DASHBOARD_MODULE_ID]), { endpointId: [
            demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CHARTS_DATA,
            demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CREATORS,
            demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES,
        ], recordPayloadMap: {
            [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CREATORS]: (state) => {
                const { routeParams: { recordId }, } = currentRouteObj(state);
                const { ambassadorsOnly, to = payloadFiltersMock.to, from = payloadFiltersMock.from, } = getDashboardFilters(state, recordId, defaultPayloadFilters);
                const mnemonicIds = recordId ? [recordId] : undefined;
                return {
                    filters: {
                        ambassadorsOnly,
                    },
                    from,
                    to,
                    mnemonicIds,
                    limit: 10,
                    sortBy: 'minutesViewed',
                    sort: 'DESC',
                };
            },
            [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CHARTS_DATA]: (state) => {
                const { routeParams: { recordId }, } = currentRouteObj(state);
                const mnemonicIds = recordId ? [recordId] : undefined;
                const { ambassadorsOnly, from, to } = getDashboardFilters(state, recordId, defaultPayloadFilters);
                return {
                    mnemonicIds,
                    from,
                    to,
                    filters: {
                        ambassadorsOnly,
                    },
                };
            },
            [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES]: (state) => {
                const { routeParams: { recordId }, } = currentRouteObj(state);
                const { ambassadorsOnly, from, to } = getDashboardFilters(state, recordId, defaultPayloadFilters);
                return {
                    from,
                    to,
                    mnemonicIds: [],
                    limit: 10,
                    filters: { ambassadorsOnly },
                };
            },
        }, onEnterActions: () => [
            {
                action: changeRoute,
                payloads: [
                    {
                        routeId: DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID,
                        routeParams: { recordId: DEMO_DASHBOARD_RECORD_ID },
                    },
                ],
            },
            {
                action: setModuleDataFilter,
                payloads: [
                    {
                        moduleId: DEMO_GRAPHS_DASHBOARD_MODULE_ID,
                        filter: 'payloadFilter',
                        id: 'from',
                        value: payloadFiltersMock.from,
                    },
                    {
                        moduleId: DEMO_GRAPHS_DASHBOARD_MODULE_ID,
                        filter: 'payloadFilter',
                        id: 'to',
                        value: payloadFiltersMock.to,
                    },
                    {
                        moduleId: DEMO_GRAPHS_DASHBOARD_MODULE_ID,
                        filter: 'ambassadorsOnly',
                        id: 'value',
                        value: false,
                    },
                ],
            },
        ] }),
};
