export const LOGIN_FORM_MODULE_ID = 'LOGIN_FORM_MODULE_ID'
export const SIGN_UP_FORM_MODULE_ID = 'SIGN_UP_FORM_MODULE_ID'
export const VERIFY_ACCOUNT_FORM_MODULE_ID = 'VERIFY_ACCOUNT_FORM_MODULE_ID'
export const VERIFICATION_SUCCESS_MODULE_ID = 'VERIFICATION_SUCCESS_MODULE_ID'
export const VERIFICATION_FAILURE_MODULE_ID = 'VERIFICATION_FAILURE_MODULE_ID'
export const VERIFICATION_FINISHED_MODULE_ID = 'VERIFICATION_FINISHED_MODULE_ID'
export const NEW_EMAIL_VERIFICATION_SUCCESS_MODULE_ID = 'NEW_EMAIL_VERIFICATION_SUCCESS_MODULE_ID'
export const NEW_EMAIL_VERIFICATION_FAILURE_MODULE_ID = 'NEW_EMAIL_VERIFICATION_FAILURE_MODULE_ID'
export const FORGOT_PASSWORD_FORM_MODULE_ID = 'FORGOT_PASSWORD_FORM_MODULE_ID'
export const RESET_PASSWORD_FORM_MODULE_ID = 'RESET_PASSWORD_FORM_MODULE_ID'
export const CONFIRM_FORGET_PASSWORD_MODULE_ID = 'CONFIRM_FORGET_PASSWORD_MODULE_ID'
export const ACCOUNT_SETTINGS_MODULE_ID = 'ACCOUNT_SETTINGS_MODULE_ID'
export const ACCOUNT_SETTINGS_BANNER_HEADER_MODULE_ID = 'ACCOUNT_SETTINGS_BANNER_HEADER_MODULE_ID'
export const HELPER_WIDGET_MODULE_ID = 'HELPER_WIDGET_MODULE_ID'
export const OAUTH_FAILURE_MODULE_ID = 'OAUTH_FAILURE_MODULE_ID'
export const UNSUBSCRIBE_MODULE_ID = 'UNSUBSCRIBE_MODULE_ID'
export const CHANGE_PASSWORD_FORM_MODULE_ID = 'CHANGE_PASSWORD_FORM_MODULE_ID'
export const CONNECTED_CHANNELS_USER_DATA_MODULE_ID = 'CONNECTED_CHANNELS_USER_DATA_MODULE_ID'
export const FOOTER_MODULE_ID = 'FOOTER_MODULE_ID'
export const NEW_FOOTER_MODULE_ID = 'NEW_FOOTER_MODULE_ID'
export const FILTER_DARES_FORM_MODULE_ID = 'FILTER_DARES_FORM_MODULE_ID'
export const SOCIAL_ICONS_MODULE_ID = 'SOCIAL_ICONS_MODULE_ID'
export const PUBLISHER_SETTINGS_MODAL_MODULE_ID = 'PUBLISHER_SETTINGS_MODAL_MODULE_ID'
export const EDIT_BRAND_KEYS_MODAL_MODULE_ID = 'EDIT_BRAND_KEYS_MODAL_MODULE_ID'
export const EDIT_BRAND_CODES_MODAL_MODULE_ID = 'EDIT_BRAND_CODES_MODAL_MODULE_ID'

export const ADD_BRAND_PRODUCT_FORM_MODULE_ID = 'ADD_BRAND_PRODUCT_FORM_MODULE_ID'
export const BRAND_SETTINGS_FORM_MODULE_ID = 'BRAND_SETTINGS_FORM_MODULE_ID'
export const DROP_UPLOAD_KEYS_FORM_MODULE_ID = 'DROP_UPLOAD_KEYS_FORM_MODULE_ID'
export const REWARDS_BULK_UPLOAD_FORM_MODULE_ID = 'REWARDS_BULK_UPLOAD_FORM_MODULE_ID'
export const DROP_UPLOAD_CODES_FORM_MODULE_ID = 'DROP_UPLOAD_CODES_FORM_MODULE_ID'
export const DROP_KEYS_LIST_MODULE_ID = 'DROP_KEYS_LIST_MODULE_ID'
export const ADD_DROP_EVENT_FORM_MODULE_ID = 'ADD_DROP_EVENT_FORM_MODULE_ID'
export const DROP_EVENT_PAGE_MODULE_ID = 'DROP_EVENT_PAGE_MODULE_ID'
export const DROP_EVENTS_LIST_MODULE_ID = 'DROP_EVENTS_LIST_MODULE_ID'
export const GET_STARTED_MODULE_ID = 'GET_STARTED_MODULE_ID'
export const SIGN_UP_MODAL_MODULE_ID = 'SIGN_UP_MODAL_MODULE_ID'
export const SIGN_IN_MODAL_MODULE_ID = 'SIGN_IN_MODAL_MODULE_ID'
export const SPONSORSHIP_DETAILS_MODULE_ID = 'SPONSORSHIP_DETAILS_MODULE_ID'
export const NO_MORE_REWARD_MODAL_MODULE_ID = 'NO_MORE_REWARD_MODULE_ID'

export const MANAGE_PAYOUT_METHOD_MODULE_ID = 'MANAGE_PAYOUT_METHOD_MODULE_ID'
export const CASH_OUT_MODULE_ID = 'CASH_OUT_MODULE_ID'
export const CREATOR_ACTIVITY_DETAILS_MODULE_ID = 'CREATOR_ACTIVITY_DETAILS_MODULE_ID'

export const WELCOME_TO_DARE_DROP_MODULE_ID = 'WELCOME_TO_DARE_DROP_MODULE_ID'
export const REWARDS_BULK_UPLOAD_FORM_MODAL_MODULE_ID = 'REWARDS_BULK_UPLOAD_FORM_MODAL_MODULE_ID'
export const SELECT_PREFERRED_PLATFORM_MODULE_ID = 'SELECT_PREFERRED_PLATFORM_MODULE_ID'

export const CREATE_PROJECT_FORM_MODULE_ID = 'CREATE_PROJECT_FORM_MODULE_ID'
export const CREATE_BRAND_PROJECT_FORM_MODULE_ID = 'CREATE_BRAND_PROJECT_FORM_MODULE_ID'
export const VIEW_PROJECT_MODULE_ID = 'VIEW_PROJECT_MODULE_ID'
export const PENDING_DELIVERIES_MODULE_ID = 'PENDING_DELIVERIES_MODULE_ID'
export const BRAND_PENDING_DELIVERIES_MODULE_ID = 'BRAND_PENDING_DELIVERIES_MODULE_ID'
export const ACCEPT_DARE_SUCCESS_MODULE_ID = 'ACCEPT_DARE_SUCCESS_MODULE_ID'
export const DARE_CREATE_SUCCESS_MODULE_ID = 'DARE_CREATE_SUCCESS_MODULE_ID'
export const SELF_DARE_CREATE_SUCCESS_MODULE_ID = 'SELF_DARE_CREATE_SUCCESS_MODULE_ID'
export const REJECT_DARE_SUCCESS_MODULE_ID = 'REJECT_DARE_SUCCESS_MODULE_ID'
export const CREATE_INTRO_DARE_SUCCESS_MODULE_ID = 'CREATE_INTRO_DARE_SUCCESS_MODULE_ID'

export const CLAIM_PROJECT_FORM_MODULE_ID = 'CLAIM_PROJECT_FORM_MODULE_ID'
export const REJECT_PROJECT_MODAL_MODULE_ID = 'REJECT_PROJECT_MODAL_MODULE_ID'
export const FOLLOW_CREATOR_MODAL_MODULE_ID = 'FOLLOW_CREATOR_MODAL_MODULE_ID'

export const STEPPER_HEADER_MODULE_ID = 'STEPPER_HEADER_MODULE_ID'
export const MY_PROJECT_BANNER_HEADER_MODULE_ID = 'MY_PROJECT_BANNER_HEADER_MODULE_ID'
export const MY_PROJECTS_LIST_MODULE_ID = 'MY_PROJECTS_LIST_MODULE_ID'
export const ACTIVE_PROJECTS_LIST_MODULE_ID = 'ACTIVE_PROJECTS_LIST_MODULE_ID'
export const CREATORS_PAGE_MODULE_ID = 'CREATORS_PAGE_MODULE_ID'
export const CREATOR_PROJECTS_LIST_MODULE_ID = 'CREATOR_PROJECTS_LIST_MODULE_ID'
export const CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID = 'CREATOR_PROJECTS_LIST_BANNER_HEADER_MODULE_ID'
export const BRAND_PROJECTS_LIST_BANNER_HEADER_MODULE_ID = 'BRAND_PROJECTS_LIST_BANNER_HEADER_MODULE_ID'
export const BRAND_PROJECTS_LIST_MODULE_ID = 'BRAND_PROJECTS_LIST_MODULE_ID'
export const TITLE_HEADER_PENDING_DELIVERIES_MODULE_ID = 'TITLE_HEADER_PENDING_DELIVERIES_MODULE_ID'
export const TWITCH_EXTENSIONS_INFO_MODULE_ID = 'TWITCH_EXTENSIONS_INFO_MODULE_ID'
export const DROP_EVENTS_LIST_BANNER_HEADER_NODULE_ID = 'DROP_EVENTS_LIST_BANNER_HEADER_NODULE_ID'

export const BRANDS_HERO_SECTION_MODULE_ID = 'BRANDS_HERO_SECTION_MODULE_ID'
export const BRANDS_SHOWCASE_MODULE_ID = 'BRANDS_SHOWCASE_MODULE_ID'
export const BRANDS_LOGO_SECTION_MODULE_ID = 'BRANDS_LOGO_SECTION_MODULE_ID'
export const BRANDS_CONTACTS_SECTION_MODULE_ID = 'BRANDS_CONTACTS_SECTION_MODULE_ID'
export const STATISTICS_SECTION_MODULE_ID = 'STATISTICS_SECTION_MODULE_ID'

export const PLEDGE_PROJECT_FORM_MODULE_ID = 'PLEDGE_PROJECT_FORM_MODULE_ID'
export const PLEDGE_SUCCESS_PAGE_MODULE_ID = 'PLEDGE_SUCCESS_PAGE_MODULE_ID'
export const DELIVER_DARE_SUCCESS_MODULE_ID = 'DELIVER_DARE_SUCCESS_MODULE_ID'
export const NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID = 'NON_USER_PLEDGE_SUCCESS_PAGE_MODULE_ID'

export const COMPLETE_WIDGET_SUCCESS_MODULE_ID = 'COMPLETE_WIDGET_SUCCESS_MODULE_ID'

export const FAQ_MODULE_ID = 'FAQ_MODULE_ID'
export const FAQ_BANNER_HEADER_MODULE_ID = 'FAQ_BANNER_HEADER_MODULE_ID'
export const TERMS_OF_SERVICE_MODULE_ID = 'TERMS_OF_SERVICE_MODULE_ID'
export const TERMS_OF_SERVICE_BANNER_HEADER_MODULE_ID = 'TERMS_OF_SERVICE_BANNER_HEADER_MODULE_ID'
export const PRIVACY_POLICY_MODULE_ID = 'PRIVACY_POLICY_MODULE_ID'
export const PRIVACY_POLICY_BANNER_HEADER_MODULE_ID = 'PRIVACY_POLICY_BANNER_HEADER_MODULE_ID'
export const COOKIE_POLICY_MODULE_ID = 'COOKIE_POLICY_MODULE_ID'
export const COOKIE_POLICY_BANNER_HEADER_MODULE_ID = 'COOKIE_POLICY_BANNER_HEADER_MODULE_ID'
export const COPYRIGHT_POLICY_MODULE_ID = 'COPYRIGHT_POLICY_MODULE_ID'
export const COPYRIGHT_POLICY_BANNER_HEADER_MODULE_ID = 'COPYRIGHT_POLICY_BANNER_HEADER_MODULE_ID'
export const RULES_OF_USE_MODULE_ID = 'RULES_OF_USE_MODULE_ID'
export const RULES_OF_USE_BANNER_HEADER_MODULE_ID = 'RULES_OF_USE_BANNER_HEADER_MODULE_ID'
export const CHANGE_PASSWORD_SUCCESS_MODULE_ID = 'CHANGE_PASSWORD_SUCCESS_MODULE_ID'

export const TITLE_HEADER_MARKETPLACE_MODULE_ID = 'TITLE_HEADER_MARKETPLACE_MODULE_ID'

export const DELIVERY_DARE_FORM_MODULE_ID = 'DELIVERY_DARE_FORM_MODULE_ID'
export const DARE_DELIVERY_DETAIL_MODULE_ID = 'DARE_DELIVERY_DETAIL_MODULE_ID'
export const BRAND_DARE_DELIVERY_DETAIL_MODULE_ID = 'BRAND_DARE_DELIVERY_DETAIL_MODULE_ID'

export const PAYOUT_ADMIN_LIST_MODULE_ID = 'PAYOUT_ADMIN_LIST_MODULE_ID'

export const ADMIN_BAN_IP_FORM_MODULE_ID = 'ADMIN_BAN_IP_FORM_MODULE_ID'
export const ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID = 'ADMIN_BANNED_ACCOUNTS_LIST_MODULE_ID'

export const COUPON_FORM_ADMIN_MODULE_ID = 'COUPON_FORM_ADMIN_MODULE_ID'
export const COUPON_LIST_ADMIN_MODULE_ID = 'COUPON_LIST_ADMIN_MODULE_ID'
export const DELETE_COUPON_MODAL_MODULE_ID = 'DELETE_COUPON_MODAL_MODULE_ID'

export const VIEW_CREATOR_DATA_MODULE_ID = 'VIEW_CREATOR_DATA_MODULE_ID'
export const FEATURED_DARES_ADMIN_LIST_MODULE_ID = 'FEATURED_DARES_ADMIN_LIST_MODULE_ID'
export const FEATURED_DARES_ADMIN_FORM_MODULE_ID = 'FEATURED_DARES_ADMIN_FORM_MODULE_ID'
export const FEATURED_DARES_LIST_MODULE_ID = 'FEATURED_DARES_LIST_MODULE_ID'

export const SHORT_URL_REPORT_MODULE_ID = 'SHORT_URL_REPORT_MODULE_ID'

export const ALERTS_AUDIO_PLAYER_MODULE_ID = 'ALERTS_AUDIO_PLAYER_MODULE_ID'
export const EDIT_PROFILE_FORM_MODULE_ID = 'EDIT_PROFILE_FORM_MODULE_ID'
export const PROJECT_COMMENTS_LIST_MODULE_ID = 'PROJECT_COMMENTS_LIST_MODULE_ID'
export const PROJECT_COMMENTS_FORM_MODULE_ID = 'PROJECT_COMMENTS_FORM_MODULE_ID'
export const COMPLETED_DARES_LIST_MODULE_ID = 'COMPLETED_DARES_LIST_MODULE_ID'
export const CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID = 'CREATE_DARE_SKIP_PLEDGE_SUCCESS_MODULE_ID'
export const PENDING_BRAND_USERS_LIST_MODULE_ID = 'PENDING_BRAND_USERS_LIST_MODULE_ID'

export const CREATORS_SECTION_MODULE_ID = 'CREATORS_SECTION_MODULE_ID'
export const CREATORS_AUTOCOMPLETE_FORM_MODULE_ID = 'CREATORS_AUTOCOMPLETE_FORM_MODULE_ID'
export const BRANDS_TESTIMONIALS_MODULE_ID = 'BRANDS_TESTIMONIALS_MODULE_ID'
export const CREATORS_TESTIMONIALS_MODULE_ID = 'CREATORS_TESTIMONIALS_MODULE_ID'
export const CREATORS_FEATURES_MODULE_ID = 'CREATORS_FEATURES_MODULE_ID'
export const BRANDS_FEATURES_MODULE_ID = 'BRANDS_FEATURES_MODULE_ID'
export const TOP_CREATORS_MODULE_ID = 'TOP_CREATORS_MODULE_ID'
export const WHAT_IS_DARE_DROP_MODULE_ID = 'WHAT_IS_DARE_DROP_MODULE_ID'
export const DROPS_BANNER_HEADER_MODULE_ID = 'DROPS_BANNER_HEADER_MODULE_ID'
export const DROPS_LIST_MODULE_ID = 'DROPS_LIST_MODULE_ID'
export const DROP_ADD_MORE_MODAL_MODULE_ID = 'DROP_ADD_MORE_MODAL_MODULE_ID'
export const BRAND_PAYMENT_MODAL_MODULE_ID = 'BRAND_PAYMENT_MODAL_MODULE_ID'
export const DROP_ADMIN_LIST_MODULE_ID = 'DROP_ADMIN_LIST_MODULE_ID'
export const DROP_ADMIN_PAYMENT_GENERATOR_FORM = 'DROP_ADMIN_PAYMENT_GENERATOR_FORM'
export const BRAND_ALTERNATE_PAYMENT_FORM_MODULE_ID = 'BRAND_ALTERNATE_PAYMENT_FORM_MODULE_ID'
export const DROP_ADMIN_LIST_SEARCH_FORM = 'DROP_ADMIN_LIST_SEARCH_FORM'
export const DROP_PREVIEW_EVENT_PAGE_MODULE_ID = 'DROP_PREVIEW_EVENT_PAGE_MODULE_ID'
export const NOT_ENOUGH_FOLLOWERS_MODULE_ID = 'NOT_ENOUGH_FOLLOWERS_MODULE_ID'
export const NO_CAMPAIGN_MODAL_MODULE_ID = 'NO_CAMPAIGN_MODAL_MODULE_ID'
export const KEYS_AND_CODES_FOR_CREATOR_MODULE_ID = 'KEYS_AND_CODES_FOR_CREATOR_MODULE_ID'
export const KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID = 'KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID'
export const MY_CODES_LIST_MODULE_ID = 'MY_CODES_LIST_MODULE_ID'
export const MY_INITIAL_PRODUCTS_MODULE_ID = 'MY_INITIAL_PRODUCTS_MODULE_ID'
export const NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID = 'NO_PUBLISHER_SETTINGS_MODAL_MODULE_ID'
export const SUCCESS_MODAL_MODULE_ID = 'SUCCESS_MODAL_MODULE_ID'
export const FAILURE_MODAL_MODULE_ID = 'FAILURE_MODAL_MODULE_ID'
export const INVOICE_SENT_CHECK_EMAIL = 'INVOICE_SENT_CHECK_EMAIL'
export const PAYPAL_DROP_EVENT_ORDER = 'PAYPAL_DROP_EVENT_ORDER'

export const VIEWERS_SHOWCASE_MODULE_ID = 'VIEWERS_SHOWCASE_MODULE_ID'
export const SET_EMAIL_FORM_MODULE_ID = 'SET_EMAIL_FORM_MODULE_ID'
export const VERIFY_NEW_EMAIL_MODULE_ID = 'VERIFY_NEW_EMAIL_MODULE_ID'
export const SET_EMAIL_FAILURE_MODULE_ID = 'SET_EMAIL_FAILURE_MODULE_ID'
export const BRAND_CODE_LIST_MODULE_ID = 'BRAND_CODE_LIST_MODULE_ID'
export const BRAND_CUSTOM_PRODUCTS_MODULE_ID = 'BRAND_CUSTOM_PRODUCTS_MODULE_ID'
export const CREATORS_PAGE_SECTIONS_MODULE_ID = 'CREATORS_PAGE_SECTIONS_MODULE_ID'
export const CROP_IMAGE_MODAL_MODULE_ID = 'CROP_IMAGE_MODAL_MODULE_ID'
export const DROPS_SLIDER_MODULE_ID = 'DROPS_SLIDER_MODULE_ID'
export const BRANDS_SLIDER_MODULE_ID = 'BRANDS_SLIDER_MODULE_ID'
export const CROP_IMAGE_MODULE_ID = 'CROP_IMAGE_MODULE_ID'
export const HOMEPAGE_ROLES_SECTION = 'HOMEPAGE_ROLES_SECTION'
export const HOMEPAGE_PUBLISHERS_SECTION = 'HOMEPAGE_PUBLISHERS_SECTION'
export const HOMEPAGE_CREATORS_SECTION = 'HOMEPAGE_CREATORS_SECTION'
export const CREATORS_PAGE_HEAD_SECTION = 'CREATORS_PAGE_HEAD_SECTION'
export const BRANDS_DARE_CREATORS_SECTION = 'BRANDS_DARE_CREATORS_SECTION'
export const BRANDS_PARTNERSHIPS_SECTION = 'BRANDS_PARTNERSHIPS_SECTION'
export const BRANDS_CREATORS_SECTION = 'BRANDS_CREATORS_SECTION'
export const HOMEPAGE_TESTIMONIALS_MODULE_ID = 'HOMEPAGE_TESTIMONIALS_MODULE_ID'
export const BRANDS_REPORTS_MODULE_ID = 'BRANDS_REPORTS_MODULE_ID'
export const NO_ACTIVE_CAMPAIGN_PAGE_MODULE_ID = 'NO_ACTIVE_CAMPAIGN_PAGE_MODULE_ID'
export const DROP_IS_OVER_MODAL_MODULE_ID = 'DROP_IS_OVER_MODAL_MODULE_ID'
export const REQUEST_INVOICE_FORM_MODULE_ID = 'REQUEST_INVOICE_FORM_MODULE_ID'
export const AMBASSADOR_PROGRAM_HEAD_SECTION = 'AMBASSADOR_PROGRAM_HEAD_SECTION'
export const AMBASSADOR_PROGRAM_BADGES_SECTION = 'AMBASSADOR_PROGRAM_BADGES_SECTION'
export const AMBASSADOR_PROGRAM_HOW_TO_SECTION = 'AMBASSADOR_PROGRAM_HOW_TO_SECTION'
export const AGENCY_PUBLISHERS_LIST_MODULE_ID = 'AGENCY_PUBLISHERS_LIST_MODULE_ID'
export const ADD_AGENCY_PUBLISHER_FORM_MODULE_ID = 'ADD_AGENCY_PUBLISHER_FORM_MODULE_ID'
export const ADD_AGENCY_ACCOUNT_MANAGER_FORM_MODULE_ID = 'ADD_AGENCY_ACCOUNT_MANAGER_FORM_MODULE_ID'
export const SIGN_UP_TOKEN_FORM_MODULE_ID = 'SIGN_UP_TOKEN_FORM_MODULE_ID'
export const DELETE_ACCOUNT_MANAGER_MODAL_MODULE_ID = 'DELETE_ACCOUNT_MANAGER_MODAL_MODULE_ID'
export const AGENCY_ACCOUNT_MANAGERS_LIST_MODULE_ID = 'AGENCY_ACCOUNT_MANAGERS_LIST_MODULE_ID'
export const CAMPAIGN_REPORT_LIST_MODULE_ID = 'CAMPAIGN_REPORT_LIST_MODULE_ID'
export const CAMPAIGN_LIST_BANNER_HEADER_MODULE_ID = 'CAMPAIGN_LIST_BANNER_HEADER_MODULE_ID'
export const SUBMIT_EMAIL_FORM_MODULE_ID = 'SUBMIT_EMAIL_FORM_MODULE_ID'
export const GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID = 'GAMING_PLATFORMS_SELECTION_MODAL_MODULE_ID'
export const REVIEW_DELIVERY_OPTIONS_MODAL_MODULE_ID = 'REVIEW_DELIVERY_OPTIONS_MODAL_MODULE_ID'
export const PUBLISHER_NOTIFICATION_SETTINGS_MODULE_ID = 'PUBLISHER_NOTIFICATION_SETTINGS_MODULE_ID'
export const TRANSFER_ACCOUNT_MODULE_ID = 'TRANSFER_ACCOUNT_MODULE_ID'
export const TIKTOK_SET_EMAIL_MODAL_MODULE_ID = 'TIKTOK_SET_EMAIL_MODAL_MODULE_ID'
export const INITIAL_PRODUCT_DISPLAY_MODAL_MODULE_ID = 'INITIAL_PRODUCT_DISPLAY_MODAL_MODULE_ID'
export const HOW_TO_EARN_POINTS_MODAL_MODULE_ID = 'HOW_TO_EARN_POINTS_MODAL_MODULE_ID'
export const POINTS_MARKETPLACE_LIST_MODULE_ID = 'POINTS_MARKETPLACE_LIST_MODULE_ID'
export const POINTS_MARKETPLACE_PURCHASE_MODAL_MODULE_ID = 'POINTS_MARKETPLACE_PURCHASE_MODAL_MODULE_ID'
export const POINTS_CASH_OUT_MODAL_MODULE_ID = 'POINTS_CASH_OUT_MODAL_MODULE_ID'
export const NOTIFICATIONS_MODAL_MODULE_ID = 'NOTIFICATIONS_MODAL_MODULE_ID'
export const WHITELIST_CREATORS_LIST_MODULE_ID = 'WHITELIST_CREATORS_LIST_MODULE_ID'
export const WHITELIST_FORM_MODAL_MODULE_ID = 'WHITELIST_FORM_MODAL_MODULE_ID'
export const GRAPHS_DASHBOARD_MODULE_ID = 'GRAPHS_DASHBOARD_MODULE_ID'
export const CAMPAIGN_CREATORS_MODULE_ID = 'CAMPAIGN_CREATORS_MODULE_ID'
export const CAMPAIGN_DASHBOARD_SLIDEOUT_TRAY = 'CAMPAIGN_DASHBOARD_SLIDEOUT_TRAY'
export const VIDEO_PLAYER_MODAL_MODULE = 'VIDEO_PLAYER_MODAL_MODULE'
export const CAMPAIGN_CLIPS_LIST_MODULE = 'CAMPAIGN_CLIPS_LIST_MODULE'
export const CAMPAIGN_DARES_LIST_MODULE = 'CAMPAIGN_DARES_LIST_MODULE'
export const CREATOR_CAMPAIGN_DARES_LIST_MODULE = 'CREATOR_CAMPAIGN_DARES_LIST_MODULE'
export const AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID = 'AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID'
export const CREATORS_AUTOCOMPLETE_MISC_MODULE_ID = 'CREATORS_AUTOCOMPLETE_MISC_MODULE_ID'
export const LANGUAGE_SWITCHER_MODULE_ID = 'LANGUAGE_SWITCHER_MODULE_ID'
export const SMITE_GIVEAWAY_BANNER_HEADER_MODULE_ID = 'SMITE_GIVEAWAY_BANNER_HEADER_MODULE_ID'
export const SMITE_GIVEAWAY_MODULE_ID = 'SMITE_GIVEAWAY_MODULE_ID'
export const DARE_SUBMISSION_MODAL_MODULE_ID = 'DARE_SUBMISSION_MODAL_MODULE_ID'
export const RENFIELD_RULES_MODULE_ID = 'RENFIELD_RULES_MODULE_ID'
export const PREVIEW_BRAND_REWARDS_MODAL_MODULE_ID = 'PREVIEW_BRAND_REWARDS_MODAL_MODULE_ID'
export const DROP_JOINED_MODAL_MODULE_ID = 'DROP_JOINED_MODAL_MODULE_ID'
export const GET_VERIFIED_MODAL_MODULE_ID = 'GET_VERIFIED_MODAL_MODULE_ID'
export const PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID = 'PUBLISHER_BAN_LIST_CREATOR_LIST_MODULE_ID'
export const PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID = 'PUBLISHER_BAN_LIST_FORM_MODAL_MODULE_ID'

// DEMO
export const DEMO_GRAPHS_DASHBOARD_MODULE_ID = 'DEMO_GRAPHS_DASHBOARD_MODULE_ID'
export const DEMO_CAMPAIGN_CREATORS_MODULE_ID = 'DEMO_CAMPAIGN_CREATORS_MODULE_ID'
export const DEMO_CAMPAIGN_CLIPS_LIST_MODULE = 'DEMO_CAMPAIGN_CLIPS_LIST_MODULE'
