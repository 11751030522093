import moment from 'moment-mini';
import { Platform } from 'root/src/shared/@types/platforms';
import { DashboardSortTypes } from 'root/src/shared/@types/dashboard';
export const defaultDashboardFilters = {
    from: moment()
        .subtract(7, 'days')
        .format(),
    to: moment().format(),
};
export const defaultCreatorsTableFilters = {
    ambassadorsOnly: false,
    platforms: [Platform.Twitch],
    currentPage: 1,
    sortBy: 'minutesViewed',
    sort: DashboardSortTypes.DESC,
};
