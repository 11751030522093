export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		audit: { type: 'string' },
		message: { type: 'string' },
		noPayout: { type: 'boolean' },
		moreRewardsExpected: { type: 'boolean' },
	},
	required: ['projectUrl', 'audit'],
	additionalProperties: false,
}
