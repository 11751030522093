import { GET_DASHBOARD_CHARTS_DATA } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'
import getDashboardChartDataPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDashboardChartDataPayloadSchema'
import getDashboardChartDataResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getDashboardChartDataResponseSchema'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { graphsData } from 'root/src/shared/descriptions/endpoints/recordTypes'

const payloadSchema = getDashboardChartDataPayloadSchema
const responseSchema = getDashboardChartDataResponseSchema

export default {
	[GET_DASHBOARD_CHARTS_DATA]: {
		endpointType: recordEndpointType,
		recordType: graphsData,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		payloadSchema,
		responseSchema,
	},
}
