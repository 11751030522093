export default {
	type: 'object',
	properties: {
		data: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					projectTitle: { type: 'string' },
					projectUrl: { type: 'string' },
					videoURL: { type: 'string' },
					viewCount: { type: 'integer' },
					likeCount: { type: 'integer' },
					commentCount: { type: 'integer' },
					shareCount: { type: 'integer' },
					thumbnail: { type: 'string' },
					creatorDisplayName: { type: 'string' },
				},
			},
		},
		totals: {
			total: {
				type: 'integer',
			},
		},
		currentPage: {
			type: 'integer',
		},
		allPage: {
			type: 'integer',
		},
	},
	required: ['data', 'totals'],
}
