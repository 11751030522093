// TODO later it probably deserves splitting, now it's fine
export const routeUrls = {
    audienceTargeting: () => '/audience-targeting',
    dashboard: () => '/campaign-dashboard',
    creatorsTwitch: (mnemonicId) => `/creators/${mnemonicId}`,
    creatorsYoutube: (mnemonicId) => `/creators/yt/${mnemonicId}`,
    creatorsTiktok: (mnemonicId) => `/creators/tiktok/${mnemonicId}`,
    creatorsFacebook: (mnemonicId) => `/creators/facebook/${mnemonicId}`,
    drop: (mnemonicId) => `/e/${mnemonicId}`,
    drops: (params) => {
        const searchParams = new URLSearchParams('');
        if (params === null || params === void 0 ? void 0 : params.tab) {
            searchParams.set('status', params.tab);
        }
        const searchParamsString = searchParams.toString();
        if (searchParamsString) {
            return `/drops?${searchParamsString}`;
        }
        return '/drops';
    },
    giveawayHistory: () => '/giveaway-history',
    brandUsers: () => '/brand-users',
    signUpToken: () => '/sign-up-token',
    signIn: () => '/sign-in',
    verifyAccount: () => '/verify-account',
    addCampaign: (formData) => {
        if (formData) {
            return `/add-campaign#formData=${formData}`;
        }
        return '/add-campaign';
    },
};
