import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import lensPath from 'ramda/src/lensPath'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import set from 'ramda/src/set'
import {
	dropKeyPlatformRegionsOptionsMap,
	readableKeyTypesMap, readablePlatformsMap,
} from 'root/src/shared/constants/dropKeys'
import { DROP_UPLOAD_KEYS_FORM_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { saveRewardKeysPayloadSchema } from 'root/src/shared/rewards/schemas/saveRewardKeys.payload.schema'
import { GamingPlatform, KeyType } from 'root/src/shared/rewards/constants'

export const DROP_UPLOAD_KEYS_PLATFORM_OPTIONS = [
	{
		label: readablePlatformsMap.steam,
		value: GamingPlatform.STEAM,
	},
	{
		label: readablePlatformsMap.epicGamesStore,
		value: GamingPlatform.EPIC_GAMES_STORE,
	},
	{
		label: readablePlatformsMap.xbox,
		value: GamingPlatform.XBOX,
	},
	{
		label: readablePlatformsMap.switch,
		value: GamingPlatform.SWITCH,
	},
	{
		label: readablePlatformsMap.ps5,
		value: GamingPlatform.PS_5,
	},
	{
		label: readablePlatformsMap['itch.io'],
		value: GamingPlatform.ITCH_IO,
	},
	{
		label: readablePlatformsMap.ios,
		value: GamingPlatform.IOS,
	},
	{
		label: readablePlatformsMap.android,
		value: GamingPlatform.ANDROID,
	},
	{
		label: readablePlatformsMap.playstationVR,
		value: GamingPlatform.PLAYSTATION_VR,
	},
	{
		label: readablePlatformsMap.oculusQuest,
		value: GamingPlatform.OCULUS_QUEST,
	},
	{
		label: readablePlatformsMap.oculusRift,
		value: GamingPlatform.OCULUS_RIFT,
	},
	{
		label: readablePlatformsMap.ps4,
		value: GamingPlatform.PS_4,
	},
	{
		label: readablePlatformsMap.xbox360,
		value: GamingPlatform.XBOX_360,
	},
	{
		label: readablePlatformsMap.xboxOne,
		value: GamingPlatform.XBOX_ONE,
	},
	{
		label: readablePlatformsMap.gog,
		value: GamingPlatform.GOG,
	},
	{
		label: readablePlatformsMap.index,
		value: GamingPlatform.INDEX,
	},
	{
		label: readablePlatformsMap.vive,
		value: GamingPlatform.VIVE,
	},
]
export const DROP_UPLOAD_KEYS_KEY_TYPE_OPTIONS = [
	{
		label: readableKeyTypesMap.retailKey,
		value: KeyType.RETAIL,
	},
	{
		label: readableKeyTypesMap.betaKey,
		value: KeyType.BETA,
	},
	{
		label: readableKeyTypesMap.completeEditionKey,
		value: KeyType.COMPLETE_EDITION,
	},
	{
		label: readableKeyTypesMap.earlyKey,
		value: KeyType.EARLY,
	},
	{
		label: readableKeyTypesMap.soundtrackKey,
		value: KeyType.SOUNDTRACK,
	},
	{
		label: readableKeyTypesMap.seasonPassKey,
		value: KeyType.SEASON_PASS,
	},
	{
		label: readableKeyTypesMap.specialKey,
		value: KeyType.SPECIAL,
	},
	{
		label: readableKeyTypesMap.dlc,
		value: KeyType.DLC,
	},
]

export const dropUploadKeysFields = [
	{
		fieldId: 'game',
		inputType: 'autoComplete',
		optionsPromiseType: 'twitchGames',
		placeholder: 'Select',
		labelFieldText: [
			{
				text: 'Select Video Game',
				required: true,
			},
		],
		required: true,
	},
	{
		labelFieldText: [
			{
				text: 'Keys',
				required: true,
			},
		],
		fieldId: 'csvKeys',
		inputType: 'attachmentInput',
		attachmentFormats: ['csv', 'txt'],
		subFieldText: 'Key .csv or .txt upload (one column, no header)',
		required: true,
	},
	{
		fieldId: 'keyType',
		labelFieldText: [
			{
				text: 'Key Type',
			},
		],
		inputType: 'dropdown',
		placeholder: readableKeyTypesMap.retailKey,
		options: DROP_UPLOAD_KEYS_KEY_TYPE_OPTIONS,
	},
	(state, { moduleKey } = {}) => {
		const keyType = pathOr(
			state.keyType,
			['form', moduleKey, 'formInputs', 'keyType', 'value'],
			state,
		)
		if (keyType !== KeyType.DLC) {
			return undefined
		}
		return {
			fieldId: 'dlcName',
			inputType: 'text',
			labelFieldText: [
				{
					text: 'DLC Name',
				},
			],
			required: true,
		}
	},
	{
		fieldId: 'platform',
		labelFieldText: [
			{
				text: 'Platform',
			},
		],
		inputType: 'dropdown',
		placeholder: readablePlatformsMap.steam,
		options: DROP_UPLOAD_KEYS_PLATFORM_OPTIONS,
	},
	(state, { moduleKey } = {}) => {
		const platform = pathOr(
			state.platform,
			['form', moduleKey, 'formInputs', 'platform', 'value'],
			state,
		) || GamingPlatform.STEAM
		return {
			fieldId: 'region',
			labelFieldText: [
				{
					text: 'Region',
				},
			],
			subFieldText:
            "Contact support@daredrop.com or the chat in the lower right if you don't see the type, platform, or region you need",
			inputType: 'dropdown',
			placeholder: 'Worldwide',
			options: dropKeyPlatformRegionsOptionsMap[platform],
		}
	},
	{
		labelFieldText: [
			{
				text: 'Restrictions',
			},
		],
		fieldId: 'restrictions',
		inputType: 'text',
		inputMaxLength: 80,
		required: false,
	},
]

export default {
	[DROP_UPLOAD_KEYS_FORM_MODULE_ID]: {
		moduleType: 'form',
		title: 'Upload Keys',
		schema: pipe(
			assoc('required', ['game', 'keyType']),
			assocPath(['properties', 'csvKeys'], {}),
			set(lensPath(['properties', 'keyType']), { type: 'object' }),
			set(lensPath(['properties', 'platform']), { type: 'object' }),
			set(lensPath(['properties', 'region']), { type: 'object' }),
			set(lensPath(['properties', 'dlcName']), { type: 'string' }),
		)(saveRewardKeysPayloadSchema),
		fields: dropUploadKeysFields,
		submits: [
			{
				label: 'Upload',
				buttonType: 'primaryButton',
			},
		],
		links: [
			{
				routeId: ACCOUNT_SETTINGS_ROUTE_ID,
				label: 'Go Back',
				dynamicPreviousRoute: true,
			},
		],
	},
}
