export default {
	type: 'object',
	properties: {
		name: {
			type: 'string',
			maxLength: 55,
		},
		cta: {
			type: 'string',
			maxLength: 25,
			errorMessage: {
				required: 'CTA is required',
				maxLength: 'max: 25 characters',
			},
		},
		url: {
			type: 'string',
			// eslint-disable-next-line no-useless-escape
			pattern: '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,11}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
			maxLength: 150,
			errorMessage: {
				pattern: 'Must be proper url',
				maxLength: 'max: 150 characters',
			},
		},
		id: { type: 'string' },
	},
	required: ['url', 'name'],
	additionalProperties: false,
}
