import { demoDashboardEndpointIds } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import getDashboardCreators from 'root/src/shared/descriptions/endpoints/getDashboardCreators';
import getDashboardDeliveries from 'root/src/shared/descriptions/endpoints/getDashboardDeliveries';
import getDashboardChartsData from 'root/src/shared/descriptions/endpoints/getDashboardChartsData';
import { DROP_GET_EVENT, GET_DASHBOARD_CHARTS_DATA, GET_DASHBOARD_CREATORS, GET_DASHBOARD_DELIVERIES, } from 'root/src/shared/descriptions/endpoints/endpointIds';
import dropGetEvent from 'root/src/server/gateway/actions/drops/dropGetEvent.endpoint';
export const demoDashboardEndpoints = {
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CREATORS]: getDashboardCreators[GET_DASHBOARD_CREATORS],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DELIVERIES]: getDashboardDeliveries[GET_DASHBOARD_DELIVERIES],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_CHARTS_DATA]: getDashboardChartsData[GET_DASHBOARD_CHARTS_DATA],
    [demoDashboardEndpointIds.DEMO_GET_DASHBOARD_DROP_EVENT]: dropGetEvent[DROP_GET_EVENT],
};
