import { DROPS_BANNER_HEADER_MODULE_ID, DROPS_LIST_MODULE_ID, } from 'root/src/shared/descriptions/modules/moduleIds';
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { dropsEventListType } from 'root/src/shared/constants/moduleTypes';
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { liveEventsKey } from 'root/src/shared/constants/keys';
import { DROP_EVENT_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds';
import { getBannerHeaderEmbeddedFilters } from 'root/src/shared/descriptions/modules/dropsBannerHeader';
export const dropsList = {
    [DROPS_LIST_MODULE_ID]: {
        moduleType: 'list',
        hideEmptyTabs: true,
        listType: dropsEventListType,
        listRouteId: DROP_EVENT_PAGE_ROUTE_ID,
        recordType: dropEvent,
        endpointId: [GET_DROP_EVENTS],
        pageItemsCount: 8,
        listPayload: (state) => {
            const { dataFilter } = getBannerHeaderEmbeddedFilters(state);
            return {
                section: liveEventsKey,
                pageItemLength: 8,
                dataFilter,
            };
        },
        sortFilterModule: DROPS_BANNER_HEADER_MODULE_ID,
    },
};
