import { authenticationTypes } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'
import { getInitialRewardPayloadSchema } from 'root/src/shared/rewards/schemas/getInitialReward.payload.schema'
import { getInitialRewardResponseSchema } from 'root/src/shared/rewards/schemas/getInitialReward.response.schema'

export const getInitialRewardEndpoint = {
	authentication: authenticationTypes.authenticated,
	// TODO: why no authentication role?
	endpointType: otherEndpointType,
	payloadSchema: getInitialRewardPayloadSchema,
	responseSchema: getInitialRewardResponseSchema,
	middlewares: [VALIDATE_TOKEN_ACCOUNT],
}
