module.exports = {
	awsHostedZoneId: 'Z07294634B6KTRLNT0IT',
	awsAuthApiId: 'byi5cmab80',
	twitchClientId: '99nxirwh7r172j54m7a4o7efisr762',
	paypalClientId: 'AVSl-LMGHPUPaMudhzZNYSxAjSpcLMlo47IMqh1hLOXIz45km57S-nNd5cSLXUJeCiUVuuwfKWILnBql',
	youtubeApiKeyClient: 'AIzaSyDP59Le3Tk55_g7W9sdSyL5nay9PoXcXeI',
	secretArns: [
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:PayPal_Test-PdO9BD',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:developmentTwitchSecret-qcbfZn',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:developmentYoutubeOAuth-ELYpfi',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:developmentGoogle-qwnqtr',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:tiktokOauthSecretDev-2rT0K3',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:paypalApiCertificateDev-b00qxd',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:cryptoDevelopment-A0kr91',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:twitchOverlaySecret-Bca8Um',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:twitchPanelSecret-Buuvp1',
		'arn:aws:secretsmanager:us-east-1:261130029928:secret:steamWebAPISecrets-RpTJfU',
	],
	shortenerPrefix: 'l',
}
