import React from 'react'
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'
import ButtonNew from 'root/src/client/web/base/ButtonNew'
import socialMedia from 'root/src/shared/constants/socialMedia'
import prop from 'ramda/src/prop'
import withStyles from '@material-ui/core/styles/withStyles'

const style = {
	root: {
		width: 76,
		height: 76,
		right: 24,
		bottom: 86,
		position: 'fixed',
		zIndex: 2147483646,
		'@media (max-device-width: 480px), (max-device-height: 480px)': {
			right: 0,
			bottom: 63,
		},
	},
	buttonContainer: {
		width: 56,
		height: 56,
		right: 12,
		bottom: 12,
		position: 'absolute',
	},
}
const DiscordWidget = ({ classes }) => (
	<div className={classes.root}>
		<div className={classes.buttonContainer}>
			<ButtonNew
				onClick={() => window.open(prop('discord', socialMedia), 'discord')}
				icon={faDiscord}
				iconSize={28}
				type="icon"
			/>
		</div>
	</div>
)

export default withStyles(style, { name: 'MuiDiscordWidget' })(DiscordWidget)
