/**
 * Cap duration of stream that we award
 */
export const STREAMS_MINUTES_LIMIT = 120;
/**
 * Duration of interval in which we store the number of viewers 04:00, 04:05, ...
 */
export const STREAM_VIEWERS_SNAPSHOT_INTERVAL_MINUTES = 5;
export const ccvToPointsRatio = {
    0: 0,
    5: 70,
    10: 110,
    25: 180,
    50: 250,
    100: 410,
    500: 440,
    1000: 530,
    2500: 620,
    5000: 660,
    10000: 700,
    20000: 830,
};
export const languagePointsRatio = {
    // undefined language cases
    '': 100,
    null: 100,
    undefined: 100,
    // defined language cases
    en: 100,
    asl: 100,
    other: 100,
    no: 98.6,
    'zh-hk': 97.3,
    da: 81.1,
    de: 80.4,
    nl: 79.8,
    sv: 76.7,
    fi: 71.1,
    fr: 63.2,
    ja: 60.7,
    ko: 60.7,
    it: 57.8,
    ca: 50.8,
    cs: 50.3,
    sk: 49.6,
    tr: 48.1,
    pl: 47.4,
    ru: 46.1,
    hu: 44.2,
    ro: 41.7,
    el: 36.8,
    bg: 31.7,
    th: 28.6,
    pt: 26.1,
    zh: 26,
    es: 25,
    uk: 18.6,
    ar: 17.3,
    id: 17,
    tl: 13,
    vi: 12,
    hi: 10.4,
    ms: 7.7,
};
