export const projectPendingKey = 'pending'
export const projectApprovedKey = 'approved'
export const projectApprovedModKey = 'approvedMod'
export const projectActiveKey = 'active'
export const projectRejectedKey = 'rejected'
export const projectDeliveryRejectedKey = 'deliveryRejected'
export const projectAcceptedKey = 'projectAccepted'
export const projectCreatorRejectedKey = 'projectCreatorRejected'
export const projectAllCreatorsRejectedKey = 'allCreatorsRejected'
export const projectDeliveryPendingKey = 'deliveryPending'
export const projectDeliveredKey = 'projectDelivered'
export const projectTitleKey = 'title'
export const projectDescriptionKey = 'description'
export const projectFavoritesAmountKey = 'favoritesAmount'
export const projectPledgersKey = 'pledgers'
export const projectUrlKey = 'projectUrl'
export const projectShareUrlKey = 'shareUrl'
export const projectCtaClicksKey = 'ctaClicks'

export const pledgeAmountKey = 'pledgeAmount'
export const amountRequestedKey = 'amountRequested'
export const platformIdKey = 'platformId'
export const platformKey = 'platform'
export const assigneePlatformKey = 'platform'
export const assigneeDisplayNameKey = 'displayName'
export const commentAmountKey = 'commentAmount'
export const repliesAmountKey = 'repliesAmount'
export const topCommentsKey = 'topComments'

export const dropEventKey = 'dropEvent'
export const commentKey = 'comment'
export const commentReplyKey = 'commentReply'
export const messageIdKey = 'messageId'
export const likesKey = 'likes'
export const dislikesKey = 'dislikes'

export const createdKey = 'created'
export const liveDateKey = 'liveDate'
export const displayNameKey = 'displayName'
export const imageKey = 'image'
export const messageKey = 'message'
export const userIdKey = 'userId'

export const idKey = 'id'
export const mnemonicIdKey = 'mnemonicId'
export const usernameKey = 'username'
export const gameNameKey = 'gameName'
export const getDropEventByGameIdKey = 'getDropEventByGameId'
export const creatorPendingKey = 'creatorPending'
export const creatorAcceptedKey = 'creatorAccepted'
export const creatorRejectedKey = 'creatorRejected'

export const projectToCaptureKey = 'projectToCapture'
export const projectToPayoutKey = 'projectToPayout'
export const payoutOutstandingKey = 'payoutOutstanding'
export const payoutAssigneesKey = 'payoutAssignees'
export const payoutCompleteKey = 'payoutComplete'
export const alertsConfigKey = 'alertsConfig'
export const alertsUrlKey = 'alertsUrl'
export const customSoundsKey = 'customSounds'
export const overlayConfigKey = 'overlayConfig'
export const obsTopDaresConfigKey = 'obsTopDaresConfig'

export const featuredDareKey = 'featuredDare'
export const alertsUrlTokenKey = 'alertsUrlToken'
export const shortTermTokenKey = 'shortTermToken'
export const profileKey = 'profile'
export const tokensKey = 'tokens'
export const userInterestsKey = 'userInterests'
export const gamePlatformsKey = 'gamePlatforms'
export const preferredPlatformKey = 'preferredPlatform'
export const termsAcceptedCampaignsKey = 'termsAcceptedCampaigns'
export const creatorPointsKey = 'creatorPoints'
export const acceptedDareMarkerKey = 'acceptedDareMarker'

// creatorData row
export const creatorDataKey = 'creatorData'
export const creatorWasLiveKey = 'creatorWasLive'
export const creatorVerifiedKey = 'creatorVerified'

export const projectKey = 'project'
export const gameKey = 'game'
export const anyGameKey = 'anyGame'
export const pledgeKey = 'pledge'
export const voidedPledgeKey = 'voidedPledge'
export const creatorKey = 'creator'
export const favoritesKey = 'favorites'
export const isFeaturedKey = 'isFeatured'
export const assigneeKey = 'assignee'
export const assigneeAcceptedKey = 'assigneeAccepted'
export const assigneeUserIdKey = 'assigneeUserId'
export const paymentFailedKey = 'paymentFailed'
export const applyExpirationFilterKey = 'applyExpirationFilterKey'
export const isPrivateKey = 'isPrivate'
export const isTwitchPanelDareKey = 'isTwitchPanelDare'
export const assigneesKey = 'assignees'
export const gamesKey = 'games'
export const darePinnedKey = 'darePinned'
export const rewardsDeliveryQueueKey = 'rewardsDeliveryQueue'
export const deliveryRatioRowKey = 'deliveryRatioRow'
export const requestedContentKey = 'requestedContent'
export const campaignTermsAcceptedKey = 'campaignTermsAccepted'

export const monetaryKey = 'monetary'

export const userKey = 'user'
export const userNotConnectedKey = 'userNotConnected'
export const unauthenticatedKey = 'unauthenticated'
export const notFoundKey = 'notFound'
export const variableSchemaKey = '^.*$'
export const accountDataKey = 'accountData'
export const agencyPublisherKey = 'agencyPublisher'
export const agencyAccountManagerKey = 'agencyAccountManager'

export const featuredDaresKey = 'featuredDares'
export const marketplaceKey = 'marketplace'
export const yourInterestsKey = 'yourInterestsKey'
export const brandProjectsKey = 'brandProjects'
export const creatorProjectsKey = 'creatorProjects'
export const myDaresKey = 'myDares'
export const activeKey = 'active'
export const privateKey = 'private'
export const completedKey = 'completed'
export const allKey = 'all'
export const daresForMeKey = 'daresForMe'
export const myFavoritesKey = 'myFavorites'
export const myPledgesKey = 'myPledges'
export const myPledgeKey = 'myPledge'
export const iCreatedKey = 'iCreated'
export const completedProjectsLikeThisKey = 'completedProjectsLikeThis'
export const overlayKey = 'overlay'

export const sectionKey = 'section'
export const subSectionKey = 'subSection'
export const userGroupKey = 'userGroup'
export const userVerificationKey = 'userVerification'
export const newEmailVerificationKey = 'newEmailVerification'
export const redirectAfterVerificationKey = 'redirectAfterVerificationKey'
export const searchCreatorsKey = 'searchCreators'
export const getCreatorsKey = 'getCreators'
export const deliveryUrlKey = 'deliveryUrl'

export const bitsOptionsKey = 'bitsOptions'
export const projectCreatorsFollowerCountKey = 'projectCreatorsFollowerCount'
export const expirationDateKey = 'expirationDate'
export const expirationRangeKey = 'expirationRange'
export const projectStateKey = 'projectState'
export const projectStateNotKey = 'projectStateNot'
export const userEventsKey = 'userEvents'
export const userLiveEventsKey = 'userLiveEvents'
export const adminKey = 'admin'
export const superAdminKey = 'superAdmin'
export const campaignIdKey = 'campaignId'
export const goingLiveDropEventsKey = 'goingLiveDropEvents'
export const goingOffDropEventsKey = 'goingOffDropEvents'
export const liveEventsKey = 'liveEvents'
export const awardPointsEventsKey = 'awardPointsEvents'
export const approvedDropEventsKey = 'approvedDropEvents'
export const notPendingDropEventsKey = 'notPendingDropEvents'
export const uniqueBrandsKey = 'uniqueBrands'
export const activeMonthlyInvoicePaymentDrops = 'activeMonthlyInvoicePaymentDrops'
export const getComingSoonEventsKey = 'getComingSoonEventsKey'
export const getPrivateLiveEventsKey = 'getPrivateLiveEvents'

export const getUserEventsWithInitialProductIdKey = 'getUserEventsWithInitialProductId'
export const createdRangeKey = 'createdRange'
export const dropEventsKey = 'dropEvents'

export const isSponsoredKey = 'isSponsored'
export const sponsorIdKey = 'sponsorId'
export const brandProductTypeKey = 'brandProductType'

export const nightbotTokenKey = 'nightbotToken'

export const typeKey = 'type'
export const levelKey = 'level'

export const ipAddressKey = 'ipAddress'
export const ipBanKey = 'ipBan'
export const accountIpBanKey = 'accountIpBan'

export const invoiceKey = 'invoice'

export const landingShortUrlKey = 'landingShortUrl'
export const shortUrlKey = 'shortUrl'
export const shortUrlDataKey = 'shortUrlData'
export const panelSettingsKey = 'panelSettings'
export const emailSubscribeKey = 'emailSubscribe'

export const subAccountInvitationKey = 'subAccountInvitation'

// creatorStreaming row
export const creatorStreamingKey = 'creatorIsStreaming'
export const promotionIntervalKey = 'promotionInterval'
export const messageTypesKey = 'messageTypes'
export const startingMinuteKey = 'startingMinute'

export const notificationSettingsKey = 'notificationSettings'
export const pendingNotificationKey = 'pendingNotification'
export const realTimeKey = 'realTime'
export const dailyKey = 'daily'
export const weeklyKey = 'weekly'
export const createdBeforeKey = 'createdBefore'
export const titlePhraseKey = 'titlePhrase'

export const productDataKey = 'productData'
export const pendingInitialProductKey = 'pendingInitialProduct'
export const couponKey = 'coupon'
export const userCouponKey = 'userCoupon'
export const brandProductKey = 'brandProduct'

export const pointsMarketplaceItemKey = 'pointsMarketplaceItem'
export const pointsTransactionKey = 'pointsTransaction'
export const paypalPointsPayoutPendingKey = 'paypalPointsPayoutPending'
export const paypalPointsPayoutDoneKey = 'paypalPointsPayoutDone'
export const paidOffPlatformKey = 'paidOffPlatform'
export const adminPointsModificationKey = 'adminPointsModification'
export const pointsAwardKey = 'pointsAward'

export const userNotificationKey = 'userNotification'
export const reconnectChannelKey = 'reconnectChannel'
export const lastAssociatedUserIdKey = 'lastAssociatedUserId'
export const payoutToSendKey = 'payoutToSend'
export const payoutSentKey = 'payoutSent'

export const transactionTypeKey = 'transactionType'
export const recipientIdKey = 'recipientId'

export const whitelistKey = 'whitelist'
export const generalWhitelistKey = 'general'
export const campaignWhitelistKey = 'campaign'
export const privateCampaignWhitelistKey = 'privateCampaignWhitelist'
export const userCampaignDataKey = 'userCampaignData'
export const unknownKey = 'unknownKey'
export const searchPlatformCreatorsKey = 'searchPlatformCreators'
export const getProjectsInfoKey = 'getProjectsInfo'
export const firstTimeDropperKey = 'firstTimeDropper'
export const getTwoWeeksExpiredDropEventsKey = 'getTwoWeeksExpiredDropEventsKey'
export const deliveryUploadedAtRangeKey = 'deliveryUploadedAtRange'
export const clipCreatedRangeKey = 'clipCreatedRange'
export const campaignIdExistsKey = 'campaignIdExists'
export const autoDeliveryApprovalKey = 'autoDeliveryApproval'
export const getExpiredDropEventsFromOneDayByDateAgoKey = 'getExpiredDropEventsFromOneDayByDateAgo'
export const publisherBanListKey = 'publisherBanList'
export const dropBanListKey = 'dropBanList'
export const getCommunityDropEventsKey = 'getCommunityDropEvents'
export const getSponsoredDropEventsKey = 'getSponsoredDropEvents'
export const getUncompletedPendingDropEventsKey = 'getUncompletedPendingDropEvents'
export const myDeliveredViewerDaresKey = 'myViewerDares'
export const myDeliveredDropDaresKey = 'myDropDares'
export const campaignIdNotExistsKey = 'campaignIdNotExists'
