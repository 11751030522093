export default {
	totals: {
		total: {
			type: 'integer',
		},
	},
	items: { type: 'array',
		items: {
			properties: {
				id: { type: 'string' },
				url: { type: 'string' },
				email: { type: 'string' },
				created: { type: 'string' },
				followerCount: { type: 'number' },
				ccv7: { type: 'number' },
				ccv14: { type: 'number' },
				ccv30: { type: 'number' },
				dropsStarted: { type: 'number' },
				pointsSum: { type: 'number' },
				pointsTotal: { type: 'number' },
				dropsCompleted: { type: 'number' },
				platform: { type: 'string' },
				mnemonicId: { type: 'number' },
				lastDropMnemonicId: { type: 'string' },
				lastActionType: { type: 'string' },
				lastActionDate: { type: 'string' },
				lastActionProjectUrl: { type: 'string' },

			},
			required: ['url', 'created', 'followerCount'],
			additionalProperties: false,
		},
	},
}
