var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { memo } from 'react';
import animationWebp from 'root/src/client/public/animation/multicoloredcircles.webp';
import Image from 'next/future/image';
import { twMerge } from 'tailwind-merge';
const LoadingCircles = memo((props) => {
    const { width = 200, height = 200 } = props, rest = __rest(props, ["width", "height"]);
    return (<div className={twMerge('flex self-center justify-self-center justify-center')}>
			<Image className="flex flex-initial self-center justify-self-center" src={animationWebp.src} alt="multicolored circles" width={width} height={height} unoptimized {...rest}/>
		</div>);
});
export default LoadingCircles;
