import always from 'ramda/src/always'
import tryCatch from 'ramda/src/tryCatch'
import concat from 'ramda/src/concat'
import pipe from 'ramda/src/pipe'
import find from 'ramda/src/find'
import head from 'ramda/src/head'
import pluck from 'ramda/src/pluck'
import join from 'ramda/src/join'
import map from 'ramda/src/map'
import path from 'ramda/src/path'
import uniq from 'ramda/src/uniq'
import { DROP_ADMIN_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { tableListType } from 'root/src/shared/constants/moduleTypes'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import {
	GET_DROP_EVENTS, ADMIN_GET_TOKEN,
} from 'root/src/shared/descriptions/endpoints/endpointIds'
import { adminKey } from 'root/src/shared/constants/keys'
import outputs from 'root/cfOutput'
import moment from 'moment'
import { CAMPAIGNS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import linkHrefSelector from 'root/src/client/logic/app/selectors/linkHrefSelector'
import changeRouteCommon from 'root/src/client/logic/brandSettings/util/changeRouteCommon'
import editDropEvent from 'root/src/client/logic/dropEvent/thunks/editDropEvent'

const adminRelog = item => async (dispatch, getState) => {
	const brandId = path(['brand', 'id'], item)
	const { body: userToken } = await invokeApiRequest(
		ADMIN_GET_TOKEN,
		{ userId: brandId },
		getState(),
	)

	const href = linkHrefSelector(
		{},
		{ routeId: CAMPAIGNS_PAGE_ROUTE_ID, hash: userToken },
	)
	changeRouteCommon(getState, href, CAMPAIGNS_PAGE_ROUTE_ID)
}

export default {
	[DROP_ADMIN_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: tableListType,
		tableColumns: [
			['Email', 'email'],
			['Publisher name', ['brand', 'brandName']],
			['Game', 'dares', pipe(pluck('games'), map(head), pluck('label'), uniq, join(', '))],
			['Campaign name', 'dropTitle', undefined, undefined, 'campaignName'],
			['Similar games', 'similarGames', pipe(pluck('label'), uniq, join(', '))],
			['Awards points', 'awardPoints', awardPoints => (awardPoints ? 'yes' : 'no')],
			['Campaign url', 'mnemonicId', concat(`${outputs.domainName}/e/`)],
			[
				'Launch date',
				'liveDate',
				liveDate => liveDate && moment.utc(liveDate, 'YYYY-MM-DD').format('MMM DD YYYY'),
			],
			[
				'Tier',
				['subscriptionData', 'planId'],
				(planId) => {
					if (!planId) return undefined
					const plan = find(
						([, value]) => value === planId,
						// eslint-disable-next-line no-undef
						Object.entries(PAYPAL_SUBSCRIPTIONS),
					)
					return tryCatch(head, always('custom'))(plan)
				},
			],
			['Plan id', ['subscriptionData', 'planId']],
			['Drop id', 'id'],
			['Initial product', 'initialProduct', initialProduct => (initialProduct ? 'yes' : 'no')],
			['Waiting for live', 'waitingForLive'],
			['Waiting for product', 'waitingForProduct'],
			[
				'CCV Breakpoints',
				'ccvBreakpoints',
				(ccvBreakpoints) => {
					if (!ccvBreakpoints) {
						return []
					}
					return map(
						breakpoint => `${breakpoint.ccv} : ${breakpoint.maxDares}`,
						ccvBreakpoints,
					)
				},
			],
			[
				'Follower Breakpoints',
				'followersBreakpoints',
				(followersBreakpoints) => {
					if (!followersBreakpoints) {
						return []
					}
					return map(
						breakpoint => `${breakpoint.followerCount} : ${breakpoint.maxDares}`,
						followersBreakpoints,
					)
				},
			],
		],
		tableItemActions: [
			{
				name: 'Edit',
				fn: editDropEvent,
				order: 1,
			},
			{
				name: 'Log-in as publisher',
				fn: adminRelog,
				order: 2,
			},
		],
		listTitle: 'Drop campaigns',
		recordType: dropEvent,
		endpointId: [GET_DROP_EVENTS],
		listPayload: {
			section: adminKey,
			pageItemLength: 20,
		},
	},
}
