import { HOW_TO_EARN_POINTS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { STREAMS_MINUTES_LIMIT } from 'root/src/shared/constants/campaignStreamingPointsRewards';
import { LinkTermTypesEnum } from 'root/src/client/v2/drop/components/SponsorshipTerms/types';
import { createLinkParametrizedIndex } from 'root/src/client/v2/drop/components/SponsorshipTerms/helpers';
export const sponsorshipRegularTerms = [
    {
        text: 'Put “#daredrop” in the stream title.',
        color: colorsNew.teal,
    },
    {
        color: colorsNew.primary,
        text: `Link to ${createLinkParametrizedIndex(0)} in chat.`,
        links: [{ linkType: LinkTermTypesEnum.SHORT_LINK }],
        visibilityCondition: ({ isTermsAccepted }) => Boolean(isTermsAccepted),
    },
    {
        color: colorsNew.primary,
        text: 'Link to (link created after you join) in chat.',
        visibilityCondition: ({ isTermsAccepted }) => !isTermsAccepted,
    },
    {
        text: ({ dropEvent }) => { var _a; return `The points you earn for this drop are based on your most viewed ${STREAMS_MINUTES_LIMIT / 60} hour(s) of content. Using your average viewership, you will earn ${(_a = dropEvent === null || dropEvent === void 0 ? void 0 : dropEvent.estimatedPointsToEarn) === null || _a === void 0 ? void 0 : _a.toLocaleString()} points.`; },
        color: colorsNew.yellow,
        visibilityCondition: ({ canEarnPoints }) => Boolean(canEarnPoints),
    },
    {
        text: 'Earn Dare rewards until the Dares expire.',
        color: colorsNew.pink,
    },
    {
        text: ({ dropEvent }) => {
            const { pointsLimit, estimatedPointsToEarn, pointsAwarded } = dropEvent;
            const reservedPoints = Math.max(0, Math.round(Math.min(pointsLimit - pointsAwarded - dropEvent.pointsReserved, estimatedPointsToEarn)));
            if (dropEvent.isLive) {
                return `Once you accept these terms, Dare Drop will reserve ${reservedPoints.toLocaleString()} points for you for 5 days. After that, points are on a first come, first served basis.`;
            }
            return `Once this drop goes live, Dare Drop will reserve ${reservedPoints.toLocaleString()} points for you for 5 days. After that, points are rewarded on a first come, first served basis.`;
        },
        color: colorsNew.green,
        visibilityCondition: ({ canEarnPoints, isTermsAccepted, canUserReclaimReservation }) => {
            return Boolean(canEarnPoints) && (!isTermsAccepted || Boolean(canUserReclaimReservation));
        },
    },
];
export const howToEarnPointsModal = {
    [HOW_TO_EARN_POINTS_MODAL_MODULE_ID]: {
        moduleType: 'modal',
        modalTitle: 'Drop Parameters',
        modalText: ({ dropEvent }) => {
            if (dropEvent.isLive) {
                return 'Welcome to our new points program! Here\'s what you need to do to earn points on this drop:';
            }
            return 'Welcome to our new points program! Your participation will only count when the drop is live. Here\'s what you need to do to earn points for this drop:';
        },
        modalContent: {
            buttonText: 'CONTINUE',
            regularTerms: sponsorshipRegularTerms,
            whitelistedTerms: [
                {
                    text: ({ dropEvent }) => `You are a partner of ${dropEvent.brand.brandName}, so you won't get points on this drop`,
                    color: colorsNew.teal,
                },
                {
                    text: 'But you can still earn Dare rewards until the Dares expire.',
                    color: colorsNew.pink,
                },
            ],
            termsAgreementText: 'I agree to the terms for earning Points on this Drop.',
        },
    },
};
