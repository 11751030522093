import { authenticationTypes } from 'root/src/shared/constants/auth'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { initialProduct } from 'root/src/shared/descriptions/endpoints/recordTypes'
import emptyPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/emptyPayloadSchema'

export const getCreatorInitialRewardsEndpoint = {
	authentication: authenticationTypes.authenticated,
	endpointType: listEndpointType,
	recordType: initialProduct,
	payloadSchema: emptyPayloadSchema,
}
