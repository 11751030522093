var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { memo } from 'react';
import classNames from 'classnames';
import linkConnector from 'root/src/client/logic/app/connectors/linkConnector';
import { linkColor, linkHoverColor, navLinkStyle, } from 'root/src/client/web/commonStyles';
import Link from 'next/link';
import { comparePropsFn } from 'root/src/shared/util/stateCompare';
import { useQueryClient } from '@tanstack/react-query';
const styles = {
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
        color: linkColor,
        '&:visited': {
            color: linkColor,
        },
        '&:hover': {
            color: linkHoverColor,
        },
    },
    navStyle: navLinkStyle,
    navMenuStyle: {
        fontSize: 16,
        fontWeight: 500,
        textDecoration: 'none',
        color: linkColor,
        '&:visited': {
            color: linkColor,
        },
        '&:hover': {
            color: linkHoverColor,
        },
        display: 'block',
    },
};
export const BaseLink = memo((props) => {
    const { linkHref, linkTarget, children, navStyle, navMenuStyle, classes, className, style, onClick, handleAuthenticationRouting, 
    // meaning that it's a Next.js link. used for avoiding Next.js behavior in certain cases.
    // for example to route certain pages through server instead of staying in client
    nextJs = true, } = props; /* intentional casting */
    const queryClient = useQueryClient();
    const Wrapper = ({ children: c }) => {
        if (nextJs) {
            return <Link href={linkHref}>{c}</Link>;
        }
        return <>{c}</>;
    };
    return (<Wrapper>
			<a className={classNames({
            [classes.link]: !navStyle && !navMenuStyle,
            [classes.navStyle]: navStyle,
            [classes.navMenuStyle]: navMenuStyle,
            'layout-column layout-align-center-center': navStyle,
            'flex layout-column layout-align-center': navMenuStyle,
        }, className)} style={style} rel="noopener noreferrer" href={linkHref} target={linkTarget} onClick={(e) => __awaiter(void 0, void 0, void 0, function* () {
            queryClient.invalidateQueries();
            const { onClickMod } = handleAuthenticationRouting({
                linkHref,
                onClick,
            });
            if (onClickMod) {
                onClickMod(e);
            }
        })}>
				{children}
			</a>
		</Wrapper>);
}, comparePropsFn([
    'linkHref', 'linkTarget', 'routeId', 'routeParams', 'username', 'hasProfile', 'children',
]));
export default linkConnector(BaseLink, styles, 'MuiLink');
