import { getPlatformsScheme } from 'root/src/shared/util/getPlatformsScheme'

export default {
	type: 'object',
	properties: {
		creatorData: {
			type: 'object',
			properties: {
				displayName: { type: 'string' },
				image: { type: 'string' },
				username: { type: 'string' },
				creatorCampaignPointsLimit: { type: 'number' },
				creatorPoints: { type: 'number' },
				creatorGamePlatforms: {
					type: 'array',
					items: {
						type: 'string',
					},
				},
				...getPlatformsScheme(),
			},
		},
		accountData: {
			type: 'object',
			properties: {
				obsExtensionAdded: { type: 'boolean' },
				deliveryRatioDenominator: { type: 'number' },
				deliveryRatioNumerator: { type: 'number' },
				brandWebsite: { type: 'string' },
				brandName: { type: 'string' },
				brandLogo: { type: 'object' },
				agencyUserId: { type: 'string' },
				preferredPlatform: { type: 'string' },
			},
		},
		tokens: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					platform: { type: 'string' },
					platformId: { type: 'string' },
					mnemonicId: { type: 'string' },
					cognitoUsername: { type: 'string' },
					displayName: { type: 'string' },
					image: { type: 'string' },
					email: { type: 'string' },
					isPreferred: { type: 'boolean' },
				},
				required: ['platform', 'platformId', 'mnemonicId'],
			},
		},
		notificationSettings: {
			type: 'object',
			properties: {
				emailAlerts: { type: 'string' },
			},
		},
		termsAcceptedCampaigns: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
		dropEvents: {
			type: 'array',
			items: {
				type: 'object',
			},
		},
	},
	required: [],
	additionalProperties: false,
}
