import path from 'ramda/src/path'
import over from 'ramda/src/over'
import lensProp from 'ramda/src/lensProp'
import replace from 'ramda/src/replace'
import omit from 'ramda/src/omit'
import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import assoc from 'ramda/src/assoc'
import pick from 'ramda/src/pick'
import pipe from 'ramda/src/pipe'
import map from 'ramda/src/map'
import has from 'ramda/src/has'
import filter from 'ramda/src/filter'
import reject from 'ramda/src/reject'
import is from 'ramda/src/is'
import { DROP_ADD_EVENT, DROP_EDIT_EVENT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import setFieldLoading from 'root/src/client/logic/form/actions/setFieldLoading'
import setSubmitsBlock from 'root/src/client/logic/form/actions/setSubmitsBlock'
import uploadFile from 'root/src/client/util/uploadFile'
import getFileHash from 'root/src/client/util/getFileHash'
import { isNilOrEmpty, mapP } from 'root/src/shared/util/ramdaPlus'
import setSubmitsLoading from 'root/src/client/logic/form/actions/setSubmitsLoading'
import { getPayloadSchema } from 'root/src/shared/descriptions/getEndpointDesc'
import formTogglesSelector from 'root/src/client/logic/form/selectors/formTogglesSelector'
import { urlRe } from 'root/src/shared/util/regexes'

const validatePayload = (dispatch, formData, moduleId, submitIndex) => {
	const { dares, gameObj, initialProduct, campaignProduct, url } = formData

	const stopLoading = async () => {
		await dispatch(setSubmitsBlock({ moduleId, isVisible: false }))
		dispatch(setFieldLoading('assets', false))
		if (typeof submitIndex === 'number') {
			dispatch(setSubmitsLoading({ moduleId, [submitIndex]: false }))
		}
	}

	if (url?.value && !urlRe.test(url.value)) {
		stopLoading()
		throw { url: 'Must be proper url' }
	}

	if (!campaignProduct && dares && dares.length > 0 && !gameObj) {
		stopLoading()
		throw { dares: 'You must pick a game to submit with dares' }
	}

	if (initialProduct) {
		const noProductAmount = filter(propEq('amount', 0), initialProduct)
		if (noProductAmount.length > 0) {
			stopLoading()
			throw { initialProduct: 'Enter the amount' }
		}
		const exceedingProductAmounts = filter(
			product => prop('amount', product) > prop('available', product),
			initialProduct,
		)
		if (exceedingProductAmounts.length > 0) {
			stopLoading()
			throw {
				initialProduct: 'Number of products chosen exceeds their available amount',
			}
		}
	}
}

const getFileUrl = async (formData, dispatch, propName) => {
	const { id } = formData
	const fileProp = prop(propName, formData)
	if (!fileProp) return undefined
	const fileToUpload = path([propName], formData)
	if (is(Array, fileToUpload)) {
		return mapP(async (asset) => {
			if (!asset || !asset.file) return asset
			const { file } = asset
			const fileHash = await getFileHash(file)
			if (id) {
				const itemHash = path(['fileHash'], asset)
				if (fileHash === itemHash) {
					return undefined
				}
			}
			return {
				url: await uploadFile(file, dispatch, 1440), // 1 day
				fileName: file.name,
				fileHash,
			}
		}, fileToUpload)
	}
	if (!fileToUpload || !fileToUpload.file) return fileToUpload
	const { file } = fileToUpload
	const fileHash = await getFileHash(file)
	if (id) {
		const itemHash = path(['fileHash'], fileToUpload)
		if (fileHash === itemHash) {
			return undefined
		}
	}
	return {
		url: await uploadFile(file, dispatch, 1440), // 1 day
		fileName: file.name,
		fileHash,
	}
}

const getOptionValue = (option) => {
	if (typeof option === 'string') {
		return option
	}
	return option?.value
}

const getFollowersBreakpoints = formData => formData?.followersBreakpoints?.map(({
	platform, maxDares, followerCount,
}) => ({
	followerCount: Number(followerCount),
	maxDares: Number(maxDares),
	platform: getOptionValue(platform),
})) ?? []

const getCcvBreakpoints = formData => formData?.ccvBreakpoints?.map(({
	ccv, maxDares,
}) => ({
	ccv: Number(ccv),
	maxDares: Number(maxDares),
})) ?? []

const getDropEventPayload = async ({
	formData,
	dispatch,
	state,
	endpointId,
	moduleKey,
}) => {
	const { dares, gameObj, initialProduct, brandLogoUrl } = formData
	const daresMod = dares && dares.length > 0
		? pipe(
			map(omit(['index'])),
			map(dare => assoc('product', pick(['id', 'name', 'type'], dare.product), dare)),
		)(dares)
		: []

	const gameObjMod = gameObj
		? over(lensProp('image'), replace('52x72', '280x320'), gameObj)
		: undefined

	const assets = await getFileUrl(formData, dispatch, 'assets')
	const campaignThumbnail = await getFileUrl(formData, dispatch, 'campaignThumbnail')
	const brandLogo = await getFileUrl(formData, dispatch, 'brandLogoUrl')
	const initialProductMod = initialProduct || null

	const endpointSchema = getPayloadSchema(endpointId)

	const dropEventPayloadProps = Object.keys(endpointSchema.properties.dropEventPayload.properties)

	const followersBreakpoints = getFollowersBreakpoints(formData)
	const ccvBreakpoints = getCcvBreakpoints(formData)

	const formToggles = formTogglesSelector(state, { moduleKey })
	const propsToOmit = ['rewardScalability', 'enableAutoApprove']

	return {
		...omit(propsToOmit, pick(dropEventPayloadProps, { ...formToggles, ...formData })),
		gameObj: gameObjMod,
		assets: assets?.length ? reject(isNilOrEmpty, assets) : [],
		...(campaignThumbnail ? { campaignThumbnail } : {}),
		dares: daresMod,
		initialProduct: initialProductMod,
		followersBreakpoints,
		ccvBreakpoints,
		brandLogoUrl: brandLogo?.url || brandLogoUrl,
	}
}

export default async ({ formData, dispatch, getState, moduleId, submitIndex, moduleKey }) => {
	const state = getState()
	validatePayload(dispatch, formData, moduleId, submitIndex)

	const endpointIdMod = has('id', formData)
		? DROP_EDIT_EVENT
		: DROP_ADD_EVENT

	const dropEventPayload = await getDropEventPayload({
		formData,
		dispatch,
		state,
		endpointId: endpointIdMod,
		moduleKey,
	})

	return {
		endpointId: endpointIdMod,
		dropEventPayload,
	}
}
