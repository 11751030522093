import { GET_DASHBOARD_CREATORS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'
import getDashboardTablesPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDashboardTablesPayloadSchema'
import getDashboardCreatorsResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getDashboardCreatorsResponseSchema'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { creator } from 'root/src/shared/descriptions/endpoints/recordTypes'

const payloadSchema = getDashboardTablesPayloadSchema
const responseSchema = getDashboardCreatorsResponseSchema

export default {
	[GET_DASHBOARD_CREATORS]: {
		endpointType: listEndpointType,
		recordType: creator,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		payloadSchema,
		responseSchema,
	},
}
