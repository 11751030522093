export default {
	type: 'object',
	properties: {
		offset: {
			type: 'integer',
			minimum: 0,
		},
		limit: {
			type: 'integer',
			minimum: 0,
			maximum: 100,
		},
		sort: {
			type: 'string',
			enum: [
				'ASC',
				'DESC',
			],
		},
		sortBy: { type: 'string' },
		from: { type: 'string' },
		to: { type: 'string' },
		mnemonicIds: {
			type: 'array',
			items: {
				type: 'string',
			},
		},
		filters: { type: 'object' },
		currentPage: { type: 'integer' },
	},
	additionalProperties: false,
}
