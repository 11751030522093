import { useReducer, createContext, useContext, useCallback, useMemo, memo, useEffect, useRef, } from 'react';
import { createPortal } from 'react-dom';
import { FocusTrap } from '@mui/base/FocusTrap';
const defaultModalOptions = {
    isCloseable: true,
    zIndex: 100,
};
const modalContext = createContext(null);
const useDocumentOverflow = () => {
    const hide = () => {
        // override with important
        document.body.style.setProperty('overflow', 'hidden', 'important');
    };
    const show = () => {
        document.body.style.setProperty('overflow', 'auto');
    };
    return {
        hide,
        show,
    };
};
const useKeydown = (key, callback) => {
    useEffect(() => {
        const handler = (e) => {
            if (e.key === key) {
                callback();
            }
        };
        document.addEventListener('keydown', handler);
        return () => {
            document.removeEventListener('keydown', handler);
        };
    }, [key, callback]);
};
/**
 * WARNING: This is an imperative modal context for programmatic control, please use with caution and only when necessary
 */
export let modalContextImperative = {};
const useModalContext = () => {
    const ctx = useContext(modalContext);
    if (!ctx) {
        throw new Error('No modal provider');
    }
    return ctx;
};
const useModalProvider = () => {
    const dialogRef = useRef(null);
    const overflow = useDocumentOverflow();
    const [modalState, dispatchModalState] = useReducer((state, action) => {
        return Object.assign(Object.assign({}, state), action);
    }, {
        modal: null,
        options: defaultModalOptions,
    });
    const show = useCallback((modal, options) => {
        dispatchModalState({
            modal,
            options: Object.assign(Object.assign({}, defaultModalOptions), options),
        });
        overflow.hide();
    }, []);
    const hide = useCallback(() => {
        dispatchModalState({
            modal: null,
            options: defaultModalOptions,
        });
        overflow.show();
    }, []);
    useKeydown('Escape', () => {
        if (modalState.options.isCloseable) {
            hide();
        }
    });
    const ctx = useMemo(() => {
        return {
            show,
            hide,
            modal: modalState.modal,
            options: modalState.options,
        };
    }, [modalState]);
    return {
        ctx,
        dialogRef,
    };
};
// TODO: add focus trap
export const ModalContextProvider = memo(({ children, }) => {
    const { ctx, dialogRef } = useModalProvider();
    const isModalVisible = Boolean(ctx.modal);
    // assign context to imperative variable
    modalContextImperative = ctx;
    return (<modalContext.Provider value={ctx}>
			<div className={isModalVisible ? 'overflow-hidden' : 'overflow-auto'}>
				{children}
			</div>
			{ctx.modal && createPortal(<>
					<Overlay />
					<dialog ref={dialogRef} open={isModalVisible} className="flex m-0 py-2 max-h-[100vh] max-h-[100svh] max-h-[100dvh] overflow-auto bg-transparent fixed left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2" style={{
                zIndex: ctx.options.zIndex,
            }}>
						<FocusTrap open>
							{ctx.modal}
						</FocusTrap>
					</dialog>
				</>, document.body)}
		</modalContext.Provider>);
});
const Overlay = memo(() => {
    var _a;
    const { hide, options } = useModalContext();
    const onOverlayClick = useCallback(() => {
        if (options.isCloseable) {
            hide();
        }
    }, [options.isCloseable]);
    return (<div onClick={onOverlayClick} className="flex backdrop-blur overflow-auto fixed top-0 left-0 w-full h-full h-lvh-full h-dvh-full h-svh-full items-center justify-center" style={{
            zIndex: ((_a = options.zIndex) !== null && _a !== void 0 ? _a : defaultModalOptions.zIndex) - 1,
        }}/>);
});
export function useModal() {
    const ctx = useModalContext();
    const { show, hide, options, } = ctx;
    return {
        hide,
        show,
        options,
    };
}
