import apiRequest from 'root/src/client/logic/api/thunks/apiRequest'
import { SET_BRAND_SETTINGS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import uploadFile from 'root/src/client/util/uploadFile'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'
import omit from 'ramda/src/omit'
import { ACCOUNT_SETTINGS_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import updateStepsCompleted from 'root/src/client/util/updateStepsCompleted'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import mixpanel from 'root/src/client/logic/analytics/mixpanel'
import { mixpanelEvents } from 'root/src/shared/constants/mixpanel'
import displayModal from 'root/src/client/logic/modal/thunks/displayModal'

const errors = {
	NotURL: {
		brandWebsite: 'Must be correct URL',
	},
	BadImageSize: {
		brandLogoUrl: 'Image must have at least 130px width',
	},
	IncorrectRatio: {
		brandLogoUrl: 'Incorect logo ratio, use Crop Image tool to fix it',
	},
	BadImage: {
		brandLogoUrl: 'Problems with image',
	},
	IncorrectTwitter: {
		brandTwitter: 'Incorrect Twitter link',
	},
}

export const brandSettingsBase = async (dispatch, getState, formData, endpointId) => {
	let payload
	const file = path(['brandLogoUrl', 'file'], formData)
	if (file) {
		const brandLogoUrl = await uploadFile(file, dispatch, 1440)
		payload = { ...formData, brandLogoUrl: `https://${brandLogoUrl}` }
	}

	const { error } = await dispatch(apiRequest(endpointId, payload || formData))

	if (error) {
		throw errors[error.code]
	}
}

export default formData => async (dispatch, getState) => {
	const redirectRoute = prop('redirectRoute', formData)
	await brandSettingsBase(dispatch, getState, omit(['redirectRoute'], formData), SET_BRAND_SETTINGS)
	mixpanel.track(mixpanelEvents.publisherDetails)
	updateStepsCompleted('hasBrandDetails', dispatch, getState)
	dispatch(displayModal(undefined))
	return pushRouteClient(redirectRoute || { routeId: ACCOUNT_SETTINGS_ROUTE_ID })
}
