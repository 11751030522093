export default {
	type: 'array',
	items: {
		type: 'object',
		properties: {
			data: {
				type: 'object',
				properties: {
					followerCount: { type: 'integer' },
					daresCompletedCount: { type: 'integer' },
					streamsPostsCount: { type: 'integer' },
					tiktokViews: { type: 'integer' },
					youtubeViews: { type: 'integer' },
					viewMinutes: { type: 'number' },
					creatorsCount: { type: 'integer' },
				},
			},
			timestamp: { type: 'string' },
		},
	},
}
