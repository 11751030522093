export const useRewardsPayloadSchema = {
	type: 'object',
	properties: {
		rewardId: { type: 'string' },
		brandId: { type: 'string' },
		amount: { type: 'number' },
	},
	required: ['rewardId', 'brandId', 'amount'],
	additionalProperties: false,
}
