import { init } from '@sentry/browser'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { SENTRY_RELEASE_VERSION, SENTRY_TUNNEL_URL } from 'root/src/shared/constants/sentry'
import appConfig from 'root/appConfig'
import pipe from 'ramda/src/pipe'
import toPairs from 'ramda/src/toPairs'
import reduce from 'ramda/src/reduce'

export const sentryClientObjMessage = (title, obj) => {
	const msg = pipe(
		toPairs,
		reduce((acc, [key, value]) => ({
			...acc,
			[key]: JSON.stringify(value, null, 2),
		}), {}),
	)(obj)

	console.error(
		title,
		msg,
	)
}

const Sentry = () => {
	// eslint-disable-next-line no-undef
	if (CLIENT_SENTRY_DSN) {
		init({
			// eslint-disable-next-line no-undef
			dsn: CLIENT_SENTRY_DSN,
			integrations: [new CaptureConsoleIntegration(
				{
					levels: ['error'],
				},
			)],
			tunnel: SENTRY_TUNNEL_URL,
			environment: 'production',
			release: `${appConfig.name}@${SENTRY_RELEASE_VERSION}`,
		})
	}
}

export default Sentry
