import { SET_EMAIL_SUBSCRIPTION_DROP } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import setEmailSubscriptionDropPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/setEmailSubscriptionDropPayloadSchema'
import { VALIDATE_TOKEN_ACCOUNT } from 'root/src/shared/descriptions/middlewares/middlewareIds'

const payloadSchema = setEmailSubscriptionDropPayloadSchema

export default {
	[SET_EMAIL_SUBSCRIPTION_DROP]: {
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.creator,
		endpointType: otherEndpointType,
		payloadSchema,
		middlewares: [VALIDATE_TOKEN_ACCOUNT],
	},
}
