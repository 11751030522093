import { faSteam } from '@fortawesome/free-brands-svg-icons/faSteam';
import { faPlaystation } from '@fortawesome/free-brands-svg-icons/faPlaystation';
import { faItchIo } from '@fortawesome/free-brands-svg-icons/faItchIo';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faAndroid } from '@fortawesome/free-brands-svg-icons/faAndroid';
import { faXbox } from '@fortawesome/free-brands-svg-icons/faXbox';
import icNintendoSwitch from 'root/src/client/public/icons/ic-nintendo-switch';
import icEpic from 'root/src/client/public/icons/ic-epic';
import icGog from 'root/src/client/public/icons/ic-gog';
import icOculus from 'root/src/client/public/icons/ic-oculus';
import icIndex from 'root/src/client/public/icons/ic-index';
import icVive from 'root/src/client/public/icons/ic-vive';
import { KeyType, KeyRegion, GamingPlatform } from 'root/src/shared/rewards/constants';
export const dropKeyPlatformRegionsMap = {
    [GamingPlatform.STEAM]: {
        worldwide: KeyRegion.WORLDWIDE,
        northAmerica: KeyRegion.NORTH_AMERICA,
        europe: KeyRegion.EUROPE,
        china: KeyRegion.CHINA,
        asia: KeyRegion.ASIA,
        russia: KeyRegion.RUSSIA,
        southAmerica: KeyRegion.SOUTH_AMERICA,
        oceania: KeyRegion.OCEANIA,
        middleEast: KeyRegion.MIDDLE_EAST,
        africa: KeyRegion.AFRICA,
        centralAmerica: KeyRegion.CENTRAL_AMERICA,
    },
    [GamingPlatform.XBOX]: {
        worldwide: KeyRegion.WORLDWIDE,
        china: KeyRegion.CHINA,
    },
    [GamingPlatform.XBOX_360]: {
        worldwide: KeyRegion.WORLDWIDE,
        china: KeyRegion.CHINA,
    },
    [GamingPlatform.XBOX_ONE]: {
        worldwide: KeyRegion.WORLDWIDE,
        china: KeyRegion.CHINA,
    },
    [GamingPlatform.SWITCH]: {
        worldwide: KeyRegion.WORLDWIDE,
        japan: KeyRegion.JAPAN,
        americas: KeyRegion.AMERICAS,
        europe: KeyRegion.EUROPE,
        oceania: KeyRegion.OCEANIA,
        china: KeyRegion.CHINA,
    },
    [GamingPlatform.PS_5]: {
        worldwide: KeyRegion.WORLDWIDE,
        americas: KeyRegion.AMERICAS,
        europe: KeyRegion.EUROPE,
        europeAndAmericas: KeyRegion.EUROPE_AND_AMERICAS,
        china: KeyRegion.CHINA,
    },
    [GamingPlatform.PS_4]: {
        worldwide: KeyRegion.WORLDWIDE,
        siea: KeyRegion.SIEA,
        siee: KeyRegion.SIEE,
        china: KeyRegion.CHINA,
    },
    [GamingPlatform.ITCH_IO]: {
        worldwide: KeyRegion.WORLDWIDE,
    },
    [GamingPlatform.PLAYSTATION_VR]: {
        worldwide: KeyRegion.WORLDWIDE,
        northAmerican: KeyRegion.NORTH_AMERICAN,
        latinAmerican: KeyRegion.LATIN_AMERICAN,
        europe: KeyRegion.EUROPE,
        middleEast: KeyRegion.MIDDLE_EAST,
        asia: KeyRegion.ASIA,
        china: KeyRegion.CHINA,
        japan: KeyRegion.JAPAN,
        korea: KeyRegion.KOREA,
    },
    [GamingPlatform.OCULUS_QUEST]: {
        worldwide: KeyRegion.WORLDWIDE,
        africa: KeyRegion.AFRICA,
        australia: KeyRegion.AUSTRALIA,
        austria: KeyRegion.AUSTRIA,
        belgium: KeyRegion.BELGIUM,
        canada: KeyRegion.CANADA,
        denmark: KeyRegion.DENMARK,
        finland: KeyRegion.FINLAND,
        france: KeyRegion.FRANCE,
        germany: KeyRegion.GERMANY,
        iceland: KeyRegion.ICELAND,
        ireland: KeyRegion.IRELAND,
        italy: KeyRegion.ITALY,
        japan: KeyRegion.JAPAN,
        netherlands: KeyRegion.NETHERLANDS,
        newZealand: KeyRegion.NEW_ZEALAND,
        norway: KeyRegion.NORWAY,
        poland: KeyRegion.POLAND,
        spain: KeyRegion.SPAIN,
        sweden: KeyRegion.SWEDEN,
        switzerland: KeyRegion.SWITZERLAND,
        taiwan: KeyRegion.TAIWAN,
        greatBritain: KeyRegion.GREAT_BRITAIN,
        unitedStates: KeyRegion.UNITED_STATES,
    },
    [GamingPlatform.OCULUS_RIFT]: {
        worldwide: KeyRegion.WORLDWIDE,
        africa: KeyRegion.AFRICA,
        australia: KeyRegion.AUSTRALIA,
        austria: KeyRegion.AUSTRIA,
        belgium: KeyRegion.BELGIUM,
        canada: KeyRegion.CANADA,
        denmark: KeyRegion.DENMARK,
        finland: KeyRegion.FINLAND,
        france: KeyRegion.FRANCE,
        germany: KeyRegion.GERMANY,
        iceland: KeyRegion.ICELAND,
        ireland: KeyRegion.IRELAND,
        italy: KeyRegion.ITALY,
        japan: KeyRegion.JAPAN,
        netherlands: KeyRegion.NETHERLANDS,
        newZealand: KeyRegion.NEW_ZEALAND,
        norway: KeyRegion.NORWAY,
        poland: KeyRegion.POLAND,
        spain: KeyRegion.SPAIN,
        sweden: KeyRegion.SWEDEN,
        switzerland: KeyRegion.SWITZERLAND,
        taiwan: KeyRegion.TAIWAN,
        greatBritain: KeyRegion.GREAT_BRITAIN,
        unitedStates: KeyRegion.UNITED_STATES,
    },
    [GamingPlatform.IOS]: {
        worldwide: KeyRegion.WORLDWIDE,
        northAmerica: KeyRegion.NORTH_AMERICA,
        europe: KeyRegion.EUROPE,
        china: KeyRegion.CHINA,
        asia: KeyRegion.ASIA,
        russia: KeyRegion.RUSSIA,
        southAmerica: KeyRegion.SOUTH_AMERICA,
        oceania: KeyRegion.OCEANIA,
        middleEast: KeyRegion.MIDDLE_EAST,
        africa: KeyRegion.AFRICA,
        centralAmerica: KeyRegion.CENTRAL_AMERICA,
    },
    [GamingPlatform.ANDROID]: {
        worldwide: KeyRegion.WORLDWIDE,
        northAmerica: KeyRegion.NORTH_AMERICA,
        europe: KeyRegion.EUROPE,
        china: KeyRegion.CHINA,
        asia: KeyRegion.ASIA,
        russia: KeyRegion.RUSSIA,
        southAmerica: KeyRegion.SOUTH_AMERICA,
        oceania: KeyRegion.OCEANIA,
        middleEast: KeyRegion.MIDDLE_EAST,
        africa: KeyRegion.AFRICA,
        centralAmerica: KeyRegion.CENTRAL_AMERICA,
    },
    [GamingPlatform.EPIC_GAMES_STORE]: {
        worldwide: KeyRegion.WORLDWIDE,
    },
    [GamingPlatform.GOG]: {
        worldwide: KeyRegion.WORLDWIDE,
    },
    [GamingPlatform.INDEX]: {
        worldwide: KeyRegion.WORLDWIDE,
        northAmerica: KeyRegion.NORTH_AMERICA,
        europe: KeyRegion.EUROPE,
        china: KeyRegion.CHINA,
        asia: KeyRegion.ASIA,
        russia: KeyRegion.RUSSIA,
        southAmerica: KeyRegion.SOUTH_AMERICA,
        oceania: KeyRegion.OCEANIA,
        middleEast: KeyRegion.MIDDLE_EAST,
        africa: KeyRegion.AFRICA,
        centralAmerica: KeyRegion.CENTRAL_AMERICA,
    },
    [GamingPlatform.VIVE]: {
        worldwide: KeyRegion.WORLDWIDE,
        northAmerica: KeyRegion.NORTH_AMERICA,
        europe: KeyRegion.EUROPE,
        china: KeyRegion.CHINA,
        asia: KeyRegion.ASIA,
        russia: KeyRegion.RUSSIA,
        southAmerica: KeyRegion.SOUTH_AMERICA,
        oceania: KeyRegion.OCEANIA,
        middleEast: KeyRegion.MIDDLE_EAST,
        africa: KeyRegion.AFRICA,
        centralAmerica: KeyRegion.CENTRAL_AMERICA,
    },
};
export const platformIconMap = {
    [GamingPlatform.STEAM]: faSteam,
    [GamingPlatform.EPIC_GAMES_STORE]: icEpic,
    [GamingPlatform.PS_4]: faPlaystation,
    [GamingPlatform.PS_5]: faPlaystation,
    [GamingPlatform.PLAYSTATION_VR]: faPlaystation,
    [GamingPlatform.SWITCH]: icNintendoSwitch,
    [GamingPlatform.XBOX]: faXbox,
    [GamingPlatform.XBOX_360]: faXbox,
    [GamingPlatform.XBOX_ONE]: faXbox,
    [GamingPlatform.IOS]: faApple,
    [GamingPlatform.ANDROID]: faAndroid,
    [GamingPlatform.OCULUS_QUEST]: icOculus,
    [GamingPlatform.OCULUS_RIFT]: icOculus,
    [GamingPlatform.GOG]: icGog,
    [GamingPlatform.ITCH_IO]: faItchIo,
    [GamingPlatform.INDEX]: icIndex,
    [GamingPlatform.VIVE]: icVive,
};
export const readableKeyTypesMap = {
    [KeyType.RETAIL]: 'Retail',
    [KeyType.SPECIAL]: 'Special',
    [KeyType.BETA]: 'Beta',
    [KeyType.COMPLETE_EDITION]: 'Complete Edition',
    [KeyType.EARLY]: 'Early Access',
    [KeyType.SOUNDTRACK]: 'Soundtrack',
    [KeyType.SEASON_PASS]: 'Season Pass',
    [KeyType.DLC]: 'DLC',
};
export const readablePlatformsMap = {
    [GamingPlatform.STEAM]: 'Steam',
    [GamingPlatform.EPIC_GAMES_STORE]: 'Epic Games Store',
    [GamingPlatform.PLAYSTATION_VR]: 'PlayStation VR',
    [GamingPlatform.PS_4]: 'PS4',
    [GamingPlatform.PS_5]: 'PS5',
    [GamingPlatform.XBOX]: 'Xbox Series X/S',
    [GamingPlatform.XBOX_360]: 'Xbox 360',
    [GamingPlatform.XBOX_ONE]: 'Xbox One',
    [GamingPlatform.SWITCH]: 'Switch',
    [GamingPlatform.ANDROID]: 'Android',
    [GamingPlatform.IOS]: 'iOS',
    [GamingPlatform.GOG]: 'GOG',
    [GamingPlatform.OCULUS_QUEST]: 'Oculus Quest',
    [GamingPlatform.OCULUS_RIFT]: 'Oculus Rift',
    [GamingPlatform.ITCH_IO]: 'itch.io',
    [GamingPlatform.INDEX]: 'Valve Index',
    [GamingPlatform.VIVE]: 'Vive',
};
export const platformStoreUrlMap = {
    [GamingPlatform.STEAM]: 'store.steampowered.com',
    [GamingPlatform.EPIC_GAMES_STORE]: 'store.epicgames.com',
    [GamingPlatform.PLAYSTATION_VR]: 'store.playstation.com',
    [GamingPlatform.PS_4]: 'store.playstation.com',
    [GamingPlatform.PS_5]: 'store.playstation.com',
    [GamingPlatform.XBOX]: 'www.xbox.com/microsoft-store',
    [GamingPlatform.XBOX_360]: 'marketplace.xbox.com/games/Xbox360Games',
    [GamingPlatform.XBOX_ONE]: 'www.xbox.com/microsoft-store',
    [GamingPlatform.SWITCH]: 'www.nintendo.com/store',
    [GamingPlatform.ANDROID]: 'play.google.com',
    [GamingPlatform.IOS]: 'www.apple.com/app-store',
    [GamingPlatform.GOG]: 'www.gog.com',
    [GamingPlatform.OCULUS_QUEST]: 'www.oculus.com/experiences/quest',
    [GamingPlatform.OCULUS_RIFT]: 'www.oculus.com/experiences/rift',
    [GamingPlatform.ITCH_IO]: 'itch.io',
    [GamingPlatform.INDEX]: 'store.steampowered.com',
    [GamingPlatform.VIVE]: 'www.vive.com',
};
export const dropKeyPlatformRegionsOptionsMap = {
    [GamingPlatform.STEAM]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].northAmerica,
            label: 'North America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].centralAmerica,
            label: 'Central America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].southAmerica,
            label: 'South America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].asia,
            label: 'Asia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].middleEast,
            label: 'Middle East',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].russia,
            label: 'Russia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.STEAM].oceania,
            label: 'Oceania',
        },
    ],
    [GamingPlatform.XBOX]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.XBOX].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.XBOX].china,
            label: 'China',
        },
    ],
    [GamingPlatform.XBOX_360]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.XBOX_360].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.XBOX_360].china,
            label: 'China',
        },
    ],
    [GamingPlatform.XBOX_ONE]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.XBOX_ONE].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.XBOX_ONE].china,
            label: 'China',
        },
    ],
    [GamingPlatform.SWITCH]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.SWITCH].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.SWITCH].americas,
            label: 'Americas',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.SWITCH].japan,
            label: 'Japan',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.SWITCH].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.SWITCH].oceania,
            label: 'Oceania',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.SWITCH].china,
            label: 'China',
        },
    ],
    [GamingPlatform.PS_5]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_5].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_5].americas,
            label: 'Americas',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_5].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_5].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_5].europeAndAmericas,
            label: 'Europe and Americas',
        },
    ],
    [GamingPlatform.PS_4]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_4].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_4].siea,
            label: 'SIEA (North America and South America)',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_4].siee,
            label: 'SIEE (Europe, Africa, Middle East and Oceania)',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PS_4].china,
            label: 'China',
        },
    ],
    [GamingPlatform.IOS]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].northAmerica,
            label: 'North America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].centralAmerica,
            label: 'Central America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].southAmerica,
            label: 'South America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].asia,
            label: 'Asia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].middleEast,
            label: 'Middle East',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].russia,
            label: 'Russia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.IOS].oceania,
            label: 'Oceania',
        },
    ],
    [GamingPlatform.ANDROID]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].northAmerica,
            label: 'North America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].centralAmerica,
            label: 'Central America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].southAmerica,
            label: 'South America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].asia,
            label: 'Asia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].middleEast,
            label: 'Middle East',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].russia,
            label: 'Russia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ANDROID].oceania,
            label: 'Oceania',
        },
    ],
    [GamingPlatform.ITCH_IO]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.ITCH_IO].worldwide,
            label: 'Worldwide',
        },
    ],
    [GamingPlatform.PLAYSTATION_VR]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].northAmerican,
            label: 'North America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].latinAmerican,
            label: 'Latin America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].middleEast,
            label: 'Middle East',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].asia,
            label: 'Asia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].japan,
            label: 'Japan',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.PLAYSTATION_VR].korea,
            label: 'Korea',
        },
    ],
    [GamingPlatform.OCULUS_QUEST]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].australia,
            label: 'Australia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].austria,
            label: 'Austria',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].belgium,
            label: 'Belgium',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].canada,
            label: 'Canada',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].denmark,
            label: 'Denmark',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].finland,
            label: 'Finland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].france,
            label: 'France',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].germany,
            label: 'Germany',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].iceland,
            label: 'Iceland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].ireland,
            label: 'Ireland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].italy,
            label: 'Italy',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].japan,
            label: 'Japan',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].netherlands,
            label: 'Netherlands',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].newZealand,
            label: 'New Zealand',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].norway,
            label: 'Norway',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].poland,
            label: 'Poland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].spain,
            label: 'Spain',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].sweden,
            label: 'Sweden',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].switzerland,
            label: 'Switzerland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].taiwan,
            label: 'Taiwan',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].greatBritain,
            label: 'Great Britain',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_QUEST].unitedStates,
            label: 'United States',
        },
    ],
    [GamingPlatform.OCULUS_RIFT]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].australia,
            label: 'Australia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].austria,
            label: 'Austria',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].belgium,
            label: 'Belgium',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].canada,
            label: 'Canada',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].denmark,
            label: 'Denmark',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].finland,
            label: 'Finland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].france,
            label: 'France',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].germany,
            label: 'Germany',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].iceland,
            label: 'Iceland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].ireland,
            label: 'Ireland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].italy,
            label: 'Italy',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].japan,
            label: 'Japan',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].netherlands,
            label: 'Netherlands',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].newZealand,
            label: 'New Zealand',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].norway,
            label: 'Norway',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].poland,
            label: 'Poland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].spain,
            label: 'Spain',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].sweden,
            label: 'Sweden',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].switzerland,
            label: 'Switzerland',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].taiwan,
            label: 'Taiwan',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].greatBritain,
            label: 'Great Britain',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.OCULUS_RIFT].unitedStates,
            label: 'United States',
        },
    ],
    [GamingPlatform.EPIC_GAMES_STORE]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.EPIC_GAMES_STORE].worldwide,
            label: 'Worldwide',
        },
    ],
    [GamingPlatform.GOG]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.GOG].worldwide,
            label: 'Worldwide',
        },
    ],
    [GamingPlatform.INDEX]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].northAmerica,
            label: 'North America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].centralAmerica,
            label: 'Central America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].southAmerica,
            label: 'South America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].asia,
            label: 'Asia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].middleEast,
            label: 'Middle East',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].russia,
            label: 'Russia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.INDEX].oceania,
            label: 'Oceania',
        },
    ],
    [GamingPlatform.VIVE]: [
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].worldwide,
            label: 'Worldwide',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].europe,
            label: 'Europe',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].northAmerica,
            label: 'North America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].centralAmerica,
            label: 'Central America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].southAmerica,
            label: 'South America',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].asia,
            label: 'Asia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].china,
            label: 'China',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].africa,
            label: 'Africa',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].middleEast,
            label: 'Middle East',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].russia,
            label: 'Russia',
        },
        {
            value: dropKeyPlatformRegionsMap[GamingPlatform.VIVE].oceania,
            label: 'Oceania',
        },
    ],
};
