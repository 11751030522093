import { DROP_GET_EVENT } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { recordEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import dropEventResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/dropEventResponseSchema'
import getDropEventPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDropEventPayloadSchema'
import { authenticationTypes } from 'root/src/shared/constants/auth'

export const responseSchema = dropEventResponseSchema
export const payloadSchema = getDropEventPayloadSchema

export default {
	[DROP_GET_EVENT]: {
		authentication: authenticationTypes.optional,
		endpointType: recordEndpointType,
		recordType: dropEvent,
		responseSchema,
		payloadSchema,
	},
}
