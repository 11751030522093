import { darePlatformOptions,	rewardForOptions } from 'root/src/shared/constants/dropEvent'
import { DARE_HINT_MAX_LENGTH } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/dropAddEventPayloadSchema'
import {
	Platform,
} from 'root/src/shared/@types/platforms'

export default {
	type: 'object',
	properties: {
		dropTitle: { type: 'string' },
		mnemonicId: { type: 'string' },
		id: { type: 'string' },
		campaignName: { type: 'string' },
		gameObj: { type: 'object' },
		liveDate: { type: 'string' },
		paymentData: {
			type: 'object',
			properties: {
				type: { type: 'string' },
			},
		},
		liveDateFormatted: { type: 'string' },
		isUserActivated: { type: 'boolean' },
		subscriptionStatus: { type: 'string' },
		status: { type: 'string' },
		approved: { type: 'boolean' },
		messageToCreator: { type: 'string' },
		initialProduct: { type: ['array', 'null'] },
		totalPrizes: { type: 'number' },
		availablePrizes: {
			type: 'object',
			properties: {
				availableKeys: { type: 'number' },
				availableCodes: { type: 'number' },
			},
		},
		similarGames: {
			type: 'array',
			minItems: 0,
			maxItems: 10,
			uniqueItems: true,
			items: {
				type: 'object',
				properties: {
					id: { type: 'number' },
					value: { type: 'number' },
					label: { type: 'string' },
					image: { type: 'string' },
				},
				required: ['id'],
			},
		},
		assets: { type: 'array' },
		youtubeURL: { type: 'string' },
		gameDescriptionBody: { type: 'string' },
		cta: { type: 'string' },
		isGameFree: { type: 'boolean' },
		url: { type: ['object', 'string'] },
		shortUrl: { type: 'string' },
		dares: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					title: { type: 'string' },
					description: { type: 'string' },
					paymentInfo: {
						type: 'object',
						properties: {
							brandProductId: { type: 'string' },
							amount: { type: 'integer' },
							paymentType: { type: 'string' },
							productType: { type: 'string' },
						},
						required: ['brandProductId', 'paymentType', 'productType'],
					},
					hint: {
						type: 'string',
						maxLength: 110,
						errorMessage: {
							maxLength: `Hint shouldn't be longer than ${DARE_HINT_MAX_LENGTH} characters.`,
						},
					},
					rewardFor: {
						type: 'string',
						enum: rewardForOptions.map(option => option.value),
					},
					estimatedTime: {
						type: 'string',
					},
					capacity: {
						type: 'object',
						properties: {
							available: { type: 'integer' },
							used: { type: 'integer' },
						},
					},
				},
				platform: {
					type: 'string',
					enum: darePlatformOptions.map(option => option.value),
				},
				isRewardScalable: {
					type: 'boolean',
				},
				required: ['title', 'description', 'paymentInfo'],
			},
		},
		nextBillingDate: { type: 'string' },
		game: {
			type: 'object',
			properties: {
				image: { type: 'string' },
				label: { type: 'string' },
				id: { type: 'number' },
				value: { type: 'number' },
			},
		},
		brand: {
			type: 'object',
			properties: {
				brandWebsite: { type: 'string' },
				brandName: { type: 'string' },
				brandLogo: { type: 'object' },
				id: { type: 'string' },
			},
		},
		created: {
			type: 'string',
		},
		followersBreakpoints: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					platform: { type: 'string', enum: ['youtube', 'tiktok'] },
					followerCount: { type: 'integer' },
					maxDares: { type: 'integer' },
				},
			},
		},
		ccvBreakpoints: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					ccv: { type: 'integer' },
					maxDares: { type: 'integer' },
				},
			},
		},
		reviews: { type: 'object' },
		tags: { type: 'array' },
		genres: { type: 'array' },
		rating: { type: 'number' },
		budget: {
			type: 'object',
			properties: {
				type: { type: 'string' },
				amount: { type: 'number' },
			},
		},
		href: { type: 'string' },
		isEmailSubscribed: { type: 'boolean' },
		expirationDate: { type: 'string' },
		awardPoints: { type: 'boolean' },
		isCommunityDrop: { type: 'boolean' },
		dealId: { type: 'string' },
		daresCountResetDate: { type: 'string' },
		campaignProduct: { type: 'string' },
		isMultiGame: { type: 'boolean' },
		campaignThumbnail: { type: 'object' },
		isPrivate: { type: 'boolean' },
		isAbleToParticipate: { type: 'boolean' },
		isCreatorPointsAllowlisted: { type: 'boolean' },
		disableUserHasGame: { type: 'boolean' },
		estimatedPointsToEarn: { type: 'number' },
		pointsReserved: { type: 'number' },
		isLive: { type: 'boolean' },
		pointsCalculationMethod: { type: 'string' },
		costPerViewHourPoints: { type: 'number' },
		pointsLimit: { type: 'number' },
		pointsAwarded: { type: 'number' },
		canUserReclaimReservation: { type: 'boolean' },
		featuredCreator: {
			type: 'object',
			properties: {
				platform: { type: 'string' },
				platformId: { type: 'string' },
				mnemonicId: { type: 'string' },
				displayName: { type: 'string' },
			},
		},
		enableAutoApprove: {
			type: 'boolean',
		},
		rewardScalability: {
			type: 'object',
			properties: {
				enabled: { type: 'boolean' },
				rewards: {
					type: 'array',
					items: {
						type: 'object',
						properties: {
							rewardId: { type: 'string' },
							increments: {
								type: 'array',
								items: {
									type: 'object',
									properties: {
										value: { type: 'number' },
										platform: { type: 'string', enum: Object.values(Platform) },
									},
								},
							},
						},
					},
				},
			},
		},
	},
	additionalProperties: false,
}
