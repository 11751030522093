import { GET_DASHBOARD_DELIVERIES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	authenticationTypes,
	userGroups,
} from 'root/src/shared/constants/auth'
import getDashboardTablesPayloadSchema from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/getDashboardTablesPayloadSchema'
import getDashboardDeliveriesResponseSchema from 'root/src/shared/descriptions/endpoints/schemas/responseSchemas/getDashboardDeliveriesResponseSchema'
import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses'
import { clip } from 'root/src/shared/descriptions/endpoints/recordTypes'

const payloadSchema = getDashboardTablesPayloadSchema
const responseSchema = getDashboardDeliveriesResponseSchema

export default {
	[GET_DASHBOARD_DELIVERIES]: {
		endpointType: listEndpointType,
		recordType: clip,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		payloadSchema,
		responseSchema,
	},
}
