export const saveRewardCodesPayloadSchema = {
	type: 'object',
	properties: {
		filename: { type: 'string' },
		name: {
			type: 'string',
			maxLength: 55,
		},
		url: {
			type: 'string',
			// eslint-disable-next-line no-useless-escape
			pattern: '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,11}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)',
			maxLength: 150,
			errorMessage: {
				pattern: 'Must be proper url',
				maxLength: 'max: 150 characters',
			},
		},
		id: { type: 'string' },
		restrictions: {
			type: 'string',
			maxLength: 80,
		},
		image: {
			type: 'string',
		},
	},
	required: ['filename', 'url', 'name'],
	additionalProperties: false,
}
